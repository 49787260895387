import {ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";
import {SystemStatusService} from "../service/system-status.service";
import {MatchmackerStatusModel} from "../model/MatchmakerStatusResponse";
import {UIChart} from "primeng/chart";

@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SystemStatusComponent implements OnInit, OnDestroy {
  changeDetector = inject(ChangeDetectorRef);
  constructor(private systemStatus: SystemStatusService) { }
  stats: MatchmackerStatusModel = {"ServersFree": 0,"ServersLocked": 0,"Servers": 0, QueuedInstances: 0, UsersInQueue: 0};
  data: any;
  knob_size = 200;
  maxInstance = 50;
  sub: any;
  connectionRetries = 0;
  ngOnInit(): void {
    this.sub = this.systemStatus.getSseStatus().subscribe({
      next: st => {
        this.connectionRetries = 0;
        this.stats = st;
        this.changeDetector.detectChanges();
      },
      error: err => {
        if (this.connectionRetries < 5) {
          setTimeout(() => {
            this.connectionRetries++;
            this.ngOnInit();
          }, 1000)
          console.log(err);
        }
      }
    })
  }

  ngOnDestroy(): void {
      this.systemStatus.closeEventSource()
  }
}

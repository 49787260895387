import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import {BehaviorSubject} from "rxjs";
import {LoaderService} from "../custom-shared/service/loader-service.service";

export interface WarnMessagesModel {
  warnMessages: Message[];
  warnTags: Message[];
}

@Injectable({
  providedIn: 'root'
})

export class Asset3dCheckerService {

  GUIDE_LINES_3D = {
    MAX_VERTEX_COUNT: 2000,
    MAX_MATERIAL_COUNT: 1,
    MAX_TEXTURES_RESOLUTION: 2048,
    NON_MANIFOLD_ISSUE: true,
    MODEL_SIZE: {
      MIN: new THREE.Vector3(0.01, 0.01, 0.01),
      MAX: new THREE.Vector3(2.5, 2.5, 2.5),
    }
  };
  warnMessages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  warnTags: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);

  constructor(private loaderService: LoaderService) { }

  async checkFBXModel(file: File): Promise<{warnMessages: Message[], warnTags: Message[]}> {
    const reader = new FileReader();
    let meshData: any = {};
    reader.onload = async (e) => {
      const arrayBuffer = e.target && e.target.result as ArrayBuffer;
      const blob = new Blob([arrayBuffer!]);
      var url = URL.createObjectURL(blob);
      const loader = new FBXLoader();
      const data = arrayBuffer && loader.parse(arrayBuffer, url);
      // TODO: refactory this code to use another method to check the mesh data
      // wait for the loader to finish
      setTimeout(() => {
        meshData = data && this.checkMeshData(data);
        console.log('Mesh Data: ', meshData);
        const msg = this.buildWarnMessages(meshData);
        this.warnMessages.next(msg.warnMessages);
        this.warnTags.next(msg.warnTags);
        this.loaderService.hideLoader();
      }, 1000);
    }
    reader.readAsArrayBuffer(file);
    return {warnMessages: [], warnTags: []};
  }

  checkMeshData(object: THREE.Group) {
    let vertexCount = 0;
    let faceCount = 0;
    let materialCount = 0;
    let size = new THREE.Vector3();
    let materials: any[] = [];
    let textureResolutionIssues: any[] = [];
    let nonManifoldIssues: any[] = [];
    let originIssues: any[] = [];
    console.log('Object group: ', object);

    // verifica dimensioni bounding box
    const aabb = new THREE.Box3();
    aabb.setFromObject(object);
    aabb.getSize(size);
    size.multiplyScalar(0.01);

    object.traverse((child: any) => {
      if (child.isMesh) {
        let geometry = child.geometry;
        faceCount += geometry.index ? geometry.index.count / 3 : geometry.attributes.position.count / 3;
        materialCount += Array.isArray(child.material) ? child.material.length : 1;

        materials.push(child.material);

        // Verifica della risoluzione delle texture
        const checkTextureResolution = (material: any) => {
          if (material.map && material.map.source && material.map.source.data) {
            console.log('Texture: ', material.map.source.data);
            const img = material.map.source.data;
            if (img.naturalWidth > 2048 || img.naturalHeight > 2048) {
              textureResolutionIssues.push({name: material.name, width: img.width, height: img.height});
            }
          }
        };

        if (Array.isArray(child.material)) {
          child.material.forEach((mat: any) => checkTextureResolution(mat));
        } else {
          checkTextureResolution(child.material);
        }

        // Verifica delle mesh non-manifold
        const manifGeom = geometry;
        manifGeom.computeVertexNormals();
        const nonManifoldEdges = this.findNonManifoldEdges(manifGeom);
        if (nonManifoldEdges.length > 0) {
          nonManifoldIssues.push(child.name || 'Unnamed Mesh');
        }

        // calcolo numero vertici
        geometry.deleteAttribute('normal');
        geometry.deleteAttribute('uv');
        geometry = BufferGeometryUtils.mergeVertices(geometry);
        vertexCount += geometry.attributes.position.count;
      }
    });

    return { vertexCount, materialCount, textureResolutionIssues, nonManifoldIssues, originIssues, size };
  }

  findNonManifoldEdges(geometry: any): any[] {
    const edges = new Map();
    const positions = geometry.attributes.position.array;
    const index = geometry.index ? geometry.index.array : null;
    const verticesCount = positions.length / 3;

    if (index) {
      // Handle indexed geometry
      for (let i = 0; i < index.length; i += 3) {
        for (let j = 0; j < 3; j++) {
          const edge = [index[i + j], index[i + (j + 1) % 3]].sort();
          const key = edge.toString();
          if (edges.has(key)) {
            edges.set(key, edges.get(key) + 1);
          } else {
            edges.set(key, 1);
          }
        }
      }
    } else {
      // Handle non-indexed geometry
      for (let i = 0; i < verticesCount; i += 3) {
        for (let j = 0; j < 3; j++) {
          const edge = [i + j, i + (j + 1) % 3].sort();
          const key = edge.toString();
          if (edges.has(key)) {
            edges.set(key, edges.get(key) + 1);
          } else {
            edges.set(key, 1);
          }
        }
      }
    }

    const nonManifoldEdges: any[] = [];
    edges.forEach((value, key) => {
      if (value > 2) {
        nonManifoldEdges.push(key);
      }
    });

    return nonManifoldEdges;
  }

  buildWarnMessages(data: any): {warnMessages: Message[], warnTags: Message[]} {
    const warnMessages: Message[] = [];
    const warnTags: Message[] = [];
    if (data.vertexCount > this.GUIDE_LINES_3D.MAX_VERTEX_COUNT) {
      warnMessages.push({
        severity: 'warn',
        detail: `Attenzione, la mesh è formata da più dei ${this.GUIDE_LINES_3D.MAX_VERTEX_COUNT} vertici consigliati.`,
        key: 'upload-mesh-tip',
      });
      warnTags.push({
        severity: 'warning',
        detail: `vertexes`,
        key: 'upload-mesh-tag',
      });
    }
    if (data.materialCount > this.GUIDE_LINES_3D.MAX_MATERIAL_COUNT) {
      warnMessages.push({
        severity: 'warn',
        detail: `Attenzione, la mesh è formata da ${data.materialCount} materiali. Numero massimo di materiali consigliato: ${this.GUIDE_LINES_3D.MAX_MATERIAL_COUNT}`,
        key: 'upload-mesh-tip',
      });
      warnTags.push({
        severity: 'warning',
        detail: `materials`,
        key: 'upload-mesh-tag',
      });
    }
    for (let i = 0; i < data.textureResolutionIssues.length; i++) {
      warnMessages.push({
        severity: 'warn',
        detail: `Attenzione, la texture ${data.textureResolutionIssues[i].name} ha risoluzione ${data.textureResolutionIssues[i].width}x${data.textureResolutionIssues[i].height}. Risoluzione massima texture consigliata: ${this.GUIDE_LINES_3D.MAX_TEXTURES_RESOLUTION}x${this.GUIDE_LINES_3D.MAX_TEXTURES_RESOLUTION}`,
        key: 'upload-mesh-tip',
      });
      warnTags.push({
        severity: 'warning',
        detail: `resolution`,
        key: 'upload-mesh-tag',
      });
    }
    /* for (let i = 0; i < data.nonManifoldIssues.length; i++) {
      if (this.GUIDE_LINES_3D.NON_MANIFOLD_ISSUE) {
        warnMessages.push({
          severity: 'warn',
          detail: `Attenzione, la mesh [${data.nonManifoldIssues[i]}] non è univocamente connessa.`,
          key: 'upload-mesh-tip',
        });
        warnTags.push({
          severity: 'warning',
          detail: `mesh`,
          key: 'upload-mesh-tag',
        });
      }
    } */
    if (data.size.x < this.GUIDE_LINES_3D.MODEL_SIZE.MIN.x || data.size.y < this.GUIDE_LINES_3D.MODEL_SIZE.MIN.y || data.size.z < this.GUIDE_LINES_3D.MODEL_SIZE.MIN.z) {
      warnMessages.push({
        severity: 'warn',
        detail: `Attenzione, il modello inserito ha dimensioni molto piccole (${data.size.x.toFixed(3)}m, ${data.size.y.toFixed(3)}m, ${data.size.z.toFixed(3)}m). Dimensioni minime consigliate: (${this.GUIDE_LINES_3D.MODEL_SIZE.MIN.x}m, ${this.GUIDE_LINES_3D.MODEL_SIZE.MIN.y}m, ${this.GUIDE_LINES_3D.MODEL_SIZE.MIN.z}m).`,
        key: 'upload-mesh-tip',
      });
      warnTags.push({
        severity: 'warning',
        detail: `small size`,
        key: 'upload-mesh-tag',
      });
    }
    if (data.size.x > this.GUIDE_LINES_3D.MODEL_SIZE.MAX.x || data.size.y > this.GUIDE_LINES_3D.MODEL_SIZE.MAX.y || data.size.z > this.GUIDE_LINES_3D.MODEL_SIZE.MAX.z) {
      warnMessages.push({
        severity: 'warn',
        detail: `Attenzione, il modello inserito ha dimensioni molto grandi (${data.size.x.toFixed(3)}m, ${data.size.y.toFixed(3)}m, ${data.size.z.toFixed(3)}m). Dimensioni massime consigliate: (${this.GUIDE_LINES_3D.MODEL_SIZE.MAX.x}m, ${this.GUIDE_LINES_3D.MODEL_SIZE.MAX.y}m, ${this.GUIDE_LINES_3D.MODEL_SIZE.MAX.z}m).`,
        key: 'upload-mesh-tip',
      });
      warnTags.push({
        severity: 'warning',
        detail: `big size`,
        key: 'upload-mesh-tag',
      });
    }
    return {warnMessages, warnTags};
  }

}

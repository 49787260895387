import {Component, inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from "../service/user.service";
import {UserEntityResponse} from "../model/UserEntityResponse";
import {UserTable} from "../model/UserTable";
import JwtUtil from "../utils/JwtUtil";
import {Page} from "../model/Page";
import { ConfirmationService, FilterMatchMode, FilterMetadata, MessageService, PrimeNGConfig } from "primeng/api";
import {catchError, forkJoin, map, of} from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-control-panel',
  templateUrl: './admin-control-panel.component.html',
  styleUrls: ['./admin-control-panel.component.scss']
})
export class AdminControlPanelComponent implements OnInit, OnChanges{
  users: UserTable[] = [];
  paginatedUser!: Page<UserEntityResponse>;
  userService = inject(UserService);
  confirmDialogService = inject(ConfirmationService);
  messageService = inject(MessageService);
  pageSize = 25;
  totalRecords: number = 100;
  totalPages = 0;
  page = 0;
  id: string | undefined;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  enabled: boolean | undefined;
  sortBy: string = "createdTimestamp";
  sortDir: string = "desc";
  filters: { [s: string]: FilterMetadata} = {}
  jwtUtil = inject(JwtUtil);
  config: PrimeNGConfig = inject(PrimeNGConfig);
  router: Router = inject(Router);
  userColumns = [
    { field: 'id', header: 'id' },
    { field: 'email', header: 'email' },
    { field: 'firstName', header: 'firstName' },
    { field: 'lastName', header: 'lastName' },
    { field: 'role', header: 'role' },
    { field: 'enabled', header: 'enabled' },
    { field: 'actions', header: 'actions' },
  ];

  loadTable() {
    this.totalPages = this.paginatedUser.totalPages;
    this.totalRecords = this.paginatedUser.totalElements;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadTable();
  }
  ngOnInit(): void {
    this.fetchUsers();
      this.config.filterMatchModeOptions = {
          text: [
              FilterMatchMode.EQUALS
          ],
          numeric: [
              FilterMatchMode.EQUALS
          ],
          date: [
              FilterMatchMode.GREATER_THAN,
              FilterMatchMode.LESS_THAN
          ]
      }
  }

  onPageChange(event: any) {
    console.log("page change" + JSON.stringify(event));
    let pageNum = event.first / event.rows;
    console.log(pageNum);
    this.page = pageNum;
    this.pageSize = event.rows;
    this.fetchUsers();
  }

  fetchUsers(): void {
    const filterParams = this.getFilterParams();
    console.log(filterParams);
    this.userService.searchUsers(filterParams["id"], filterParams["email"], filterParams["firstName"], filterParams["lastName"], filterParams["enabled"],
      this.page, this.pageSize, this.sortBy, this.sortDir
    ).subscribe({
      next: userEntityResponse => {
        console.log(userEntityResponse);
        this.paginatedUser = userEntityResponse;
        this.totalRecords = userEntityResponse.totalElements;
        this.totalPages = userEntityResponse.totalPages;
        const userObservables = userEntityResponse.content.map(user =>
          this.userService.getUserRoleMappings(user.id).pipe(
            catchError(error => {
              console.error('Error fetching roles for user', user.id, error);
              return of([]);
            }),
            map(roleMappings => {
              const roleScore: { [key: string]: number } = {'admin': 4, 'curator': 3, 'user': 2, 'guest': 1};
              let highestRoleScore = 1;
              let highestRole = "guest";

              roleMappings.forEach(roleMapping => {
                if (roleMapping.name in roleScore) {
                  const currentRoleScore = roleScore[roleMapping.name];
                  if (currentRoleScore > highestRoleScore) {
                    highestRoleScore = currentRoleScore;
                    highestRole = roleMapping.name;
                  }
                }
              });

              return {
                id: user.id,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                enabled: user.enabled,
                role: highestRole
              };
            })
          )
        );

        forkJoin(userObservables).subscribe(userTables => {
          this.users = userTables;
        });
      }
    });
  }

  onFilter(event: any) {
    console.log(event.filters);
    this.filters = event.filters;
    this.fetchUsers();
  }

  onSort(event: any) {
    console.log(event);
    this.sortBy = event.field;
    this.sortDir = event.order == 1 ? "asc" : "desc";
    this.fetchUsers();
  }

  private getFilterParams() {
    let params: {[key: string]: any} = {};
    for (let filterKey in this.filters) {
      console.log(filterKey);
      const filter = this.filters[filterKey];
      console.log(filter);
      // @ts-ignore
      params[filterKey] = filter[0].value;
    }
    console.log(params);
    return params;
  }

}

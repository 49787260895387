import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, OnChanges,
  OnInit, SimpleChanges, ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {Config, PixelStreaming} from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.3';
import {
  Application,
  PixelStreamingApplicationStyle,
  UIElementCreationMode
} from '@epicgames-ps/lib-pixelstreamingfrontend-ui-ue5.3';
import {LoginService} from "./service/login.service";
import {NotificationService} from "./service/notification.service";
import {Message, MessageService} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "./service/user.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'fe-museo';
  isAuthenticate = false
  constructor(private notificationService: NotificationService,
              private changeDetectorRef: ChangeDetectorRef,
              private messageService: MessageService,
              private loginService: LoginService,
              private userService: UserService,
              private router: Router,
              private translate: TranslateService,
              private route: ActivatedRoute) {
    translate.setDefaultLang('it-IT');
    translate.addLangs(['en', 'it-IT', 'fr-FR', 'de-DE', 'es-ES']);
    const browserLanguageByAvailability = translate.getLangs().find(x => x === translate.getBrowserCultureLang()) ||
      translate.getLangs().find(x => x === translate.getBrowserLang());
    const localLanguage = localStorage.getItem('lang');
    translate.use(this.normalizeLanguage(localLanguage || browserLanguageByAvailability || translate.getDefaultLang()));
  }
  normalizeLanguage(lang: string|undefined): string {
    switch (lang?.toLocaleLowerCase()) {
      case 'en' || 'us' || 'gb' || 'en-us' || 'en-gb':
        return 'en';
      case 'it' || 'it-it':
        return 'it-IT';
      case 'fr' || 'fr-fr':
        return 'fr-FR';
      case 'de' || 'de-de':
        return 'de-DE';
      case 'es' || 'es-es':
        return 'es-ES';
      default:
        return 'it-IT';
    }
  }
  ngOnInit() {
    this.notificationService.message.subscribe(msg => {
      this.messageService.clear();
      this.messageService.add({severity: "error", summary:  msg || "There was an error."})
      this.changeDetectorRef.detectChanges()
    });
    this.loginService.isAuthenticatedUser().subscribe(res => {
      this.isAuthenticate = res;
      if (this.isAuthenticate) {
        this.userService.setCurrentUser();
      }
    });
  }

  showNavBar(): boolean {
    if (!this.route.snapshot.firstChild || this.route.snapshot.firstChild?.data['hideNavBar']) {
      return false;
    } else {
      return true;
    }
  }

}

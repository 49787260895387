import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

export interface RemoteLogEntryModel {
  [key: string]: any;
}
export interface ExternalLogModel {
  id?: number;
  application_name: string;
  log_entry: RemoteLogEntryModel;
  log_level?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RemoteLogService {

  apiUrl = "";
  applicationName = "ObservaBDI";

  constructor(private httpClient: HttpClient) {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`
  }

  sendRemoteLog(log: RemoteLogEntryModel = {}, level: string = 'info'): Observable<any> {
    const logEntry: ExternalLogModel = {
      application_name: this.applicationName,
      log_level: level,
      log_entry: log
    }
    return this.httpClient.post(this.apiUrl + '/logs', logEntry);
  }

  getRemoteLogs(): Observable<ExternalLogModel[]> {
    return this.httpClient.get<ExternalLogModel[]>(this.apiUrl + '/logs');
  }
  deleteRemoteLog(id: number): Observable<any> {
    return this.httpClient.delete(this.apiUrl + `/logs/${id}`, {responseType: 'text'});
  }
}

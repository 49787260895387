<div class="container-lg">
  <div class="form-container mx-auto d-block">
    <div class="title-page">
      <span>Report</span>
    </div>
    <form class="report-form" [formGroup]="reportForm" (ngSubmit)="onSubmit()">
      
      <div class="report-form-controls">
        <p-calendar formControlName="timeStart" dateFormat="dd/mm/yy" [minDate]="MIN_DATE" placeholder="{{ 'info.dateStart' | translate }}" />
        <p-calendar formControlName="timeStop" dateFormat="dd/mm/yy" [minDate]="MIN_DATE" placeholder="{{ 'info.dateEnd' | translate }}" />
        <p-dropdown
          styleClass="edit-user-dropdown"
          formControlName="exportType"
          [options]="reportTypeList"
          placeholder="Type"
        >
          <ng-template let-type pTemplate="selectedItem">
            {{ (type ? 'reportType.' + type : '') | translate }}
          </ng-template>
          <ng-template let-type pTemplate="item">
            {{ 'reportType.' + type | translate }}
          </ng-template>
        </p-dropdown>
      </div>

      <p-button type="submit" styleClass="stat-export-btn" [disabled]="reportForm.invalid || (!!exportRow && exportRow.status === 'pending')">{{ 'export.exportBtn' | translate }}</p-button>

    </form>

    <p-messages key="statisticsExportError" [closable]="false" />

    <div *ngIf="exportRow && this.exportRow.token" class="export-row">
      <div>
        <span>{{ exportRow.timeFilter.start | date:'dd/MM/YYYY' }} - </span>
        <span>{{ exportRow.timeFilter.end | date:'dd/MM/YYYY' }}</span>
      </div>
      <span>{{ 'reportType.' + exportRow.type | translate }}</span>
      <p-tag *ngIf="exportRow.status === 'pending'" severity="warning" value="In corso"/>
      <p-tag *ngIf="exportRow.status === 'ready'" severity="success" value="Terminato"/>
      <p-tag *ngIf="exportRow.status === 'error'" severity="danger" value="Errore"/>
      <p-progressSpinner *ngIf="exportRow.status === 'pending'" styleClass="w-4rem h-4rem export-spinner" strokeWidth="8" />
      <p-button [disabled]="exportRow.status !== 'ready'" styleClass="stat-export-btn" (onClick)="downloadFile()">Download</p-button>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    SpinnerComponent,
    LanguageSelectorComponent
  ],
  exports: [
    SpinnerComponent,
    LanguageSelectorComponent
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    DropdownModule,
    FormsModule
  ]
})
export class CustomSharedModule { }

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'metricTime',
})
export class MetricTimePipe implements PipeTransform {

    transform(value: number): string {
       const minutes = Math.floor(value / 60000);
       const seconds = Math.round((value - minutes * 60000) / 1000);
       return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    }

}
<div id="streaming-retry" *ngIf="!isPixelStreamingActive">
    <div class="col-md-6 col-lg-5 mx-auto">
      <h3 class="mb-4 text-center" *ngIf="!invalidExhibition && !noExhibitions && !inQueue">{{'pixelstreaming.loading'|translate}}</h3>
      <h3 class="mb-4 text-center" *ngIf="invalidExhibition">{{'pixelstreaming.invalidExhibition'|translate}}</h3>
      <ng-container *ngIf="noExhibitions">
        <div class="container my-5">
          <img src="assets/image/no-exhibition.png" class="img-fluid mb-3">
          <h3 class="mb-4 text-center">{{'pixelstreaming.noExhibition.label1'|translate}}</h3>
          <h3 class="mb-4 text-center">{{'pixelstreaming.noExhibition.label2'|translate}}</h3>
        </div>
      </ng-container>      
      <ng-container *ngIf="!invalidExhibition && !noExhibitions && inQueue">
        <h3 class="mb-4 text-center">{{'pixelstreaming.queueTitle'|translate}}</h3>
        <p *ngIf="inQueue">{{'pixelstreaming.queueDescription'|translate}} {{queuePosition}}</p>
      </ng-container>
    </div>
</div>
<div *ngIf="isPixelStreamingActive">

  <ng-container *ngIf="!isPixelStreamingVisible">
    <p-galleria
      [value]="tutorialImages"
      [showItemNavigators]="tutorialImages.length > 1"
      [showThumbnails]="false"
    >
      <ng-template pTemplate="item" let-item>
          <img [src]="item.itemImageSrc" style="width: 100%; display: block;" />
      </ng-template>
    </p-galleria>
    <p-progressBar *ngIf="!isPixelStreamingReady" class="ps-loader" [value]="sTimer" [showValue]="false"></p-progressBar>
    <ng-container *ngIf="isPixelStreamingReady">
      <p-button *ngIf="isAutoTutorial" class="show-ps-button" (onClick)="goToPixelStreaming()">{{ 'enter' | translate }}</p-button>
      <p-button *ngIf="!isAutoTutorial" class="show-ps-button" (onClick)="hideTutorialBtn()">{{ 'back' | translate }}</p-button>
    </ng-container>
  </ng-container>

  <app-pixel-streaming-wrapper [ngStyle]="{'visibility': isPixelStreamingVisible ? 'visible': 'hidden'}" [exhibitionId]="exhibitionId" id="pixel-streaming-comp"></app-pixel-streaming-wrapper>
</div>

import { Component, OnInit } from '@angular/core';
import { LastActivePageService } from '../service/last-active-page.service';

@Component({
  selector: 'app-delete-account-success-landing-page',
  templateUrl: './delete-account-success-landing-page.component.html',
  styleUrls: ['./delete-account-success-landing-page.component.scss']
})
export class DeleteAccountSuccessLandingPageComponent implements OnInit {
  constructor(private lastActivePageService: LastActivePageService) { }
  ngOnInit(): void {
    this.lastActivePageService.storeLastActivePage('/');
  }
}

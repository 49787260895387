import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignalingServerResponse } from '../model/SignalingServerResponse';
import {Observable, of} from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatchmakingService {

  constructor(private http: HttpClient) { }

  getMatchmaking(token: string): Observable<SignalingServerResponse> {
    if (environment.matchmakingEnable) {
      return this.http.get<SignalingServerResponse>(environment.matchmakingDomain + '/signallingserver', {headers: {token: token}});
    } else {
      return of(<SignalingServerResponse>{signallingServer: environment.matchmakingTestDomain, signallingPort: '443'});
    }
  }
}

import {Component, ElementRef, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ExhibitionEventResponse} from "../model/ExhibitionEventResponse";
import {AssetEventResponse} from "../model/AssetEventResponse";
import {PaginatedResponse} from "../model/PaginatedResponse";
import {PaginatedRequest} from "../model/PaginatedRequest";
import {ExhibitionEventFilter} from "../model/ExhibitionEventFilter";
import {MetricsService, SortCriteria} from "../service/metrics.service";
import {AssetEventFilter} from "../model/AssetEventFilter";
import {FilterMatchMode, FilterMetadata, FilterService, PrimeNGConfig} from "primeng/api";
import {CuratorEventResponse} from "../model/CuratorEventResponse";
import {CuratorEventFilter} from "../model/CuratorEventFilter";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import * as moment from 'moment';

@Component({
  selector: 'app-table-metrics',
  templateUrl: './table-metrics.component.html',
  styleUrls: ['./table-metrics.component.scss']
})
export class TableMetricsComponent implements OnChanges, OnInit {
  @ViewChild('modalContentText') modalContentTextRef!: ElementRef
  @Input() tableData!: PaginatedResponse<CuratorEventResponse>;
  private metricsService: MetricsService = inject(MetricsService);
  private config: PrimeNGConfig = inject(PrimeNGConfig);
  private filterService: FilterService = inject(FilterService);
  private sanitizer: DomSanitizer = inject(DomSanitizer);
  filters: { [s: string]: FilterMetadata} = {}
  pageSize = 25;
  page = 0;
  totalPages = 0;
  totalRecords: number = 100;
  curatorEventColumns: any[] = [];
  globalFilterFields: string[] = [];
  paginatedRequest: PaginatedRequest<CuratorEventFilter> & SortCriteria = {
    page: this.page,
    pageSize: this.pageSize,
    request: {} as CuratorEventFilter
  };
  displayModal: boolean = false;
  modalContent: SafeHtml = '';

  entityTypeOptions = [ 'ASSET', 'EXHIBITION' ];
  eventTypeOptions = [ 'CREATE', 'DELETE', 'UPDATE' ];

  openModal(content: string): void {
    content = content.replace(/\\"/g, '"').replace(/\\\\/g, '\\');
    content = content.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
    content = content.replace(/,/g, ", \n");
    this.modalContent = this.sanitizer.bypassSecurityTrustHtml(content);
    this.displayModal = true;
  }

  ngOnInit(): void {
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.EQUALS
      ],
      numeric: [
        FilterMatchMode.EQUALS
      ],
      date: [
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.LESS_THAN
      ]
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["tableData"] && changes["tableData"].currentValue) {
      this.loadTable()
    }
  }
  loadTable() {
    this.totalPages = this.tableData.totalPages;
    this.totalRecords = this.tableData.totalRecords;
    this.globalFilterFields = this.tableData.data.length > 0 ? Object.keys(this.tableData.data[0]) : []
    this.curatorEventColumns = [
      { field: 'entityId', header: 'logs.entityId' },
      { field: 'entityName', header: 'logs.entityName' },
      { field: 'userId', header: 'logs.userId' },
      { field: 'username', header: 'logs.username' },
      { field: 'entityType', header: 'logs.entityType'},
      { field: 'eventType', header: 'logs.eventType' },
      { field: 'eventDate', header: 'logs.eventDate'},
      { field: 'eventData', header: 'logs.eventData'},
    ];
  }

  onPageChange(event: any) {
    console.log("page change event " + JSON.stringify(event))
    let pageNum = event.first / event.rows
    this.totalPages = this.tableData.totalPages
    console.log("total pages " + this.totalPages)
    console.log("total records " + this.totalRecords)
    this.totalRecords = (this.totalPages - 1) * this.pageSize + this.tableData.data.length
    this.paginatedRequest.page = pageNum;
    this.paginatedRequest.pageSize = event.rows;
    this.paginatedRequest.request = this.createFilterRequest();
    this.loadFilteredData();
    console.log(this.page);
    console.log(this.paginatedRequest)
    this.metricsService.getCuratorMetrics(this.paginatedRequest).subscribe({
      next: data => {
        console.log(data);
        this.tableData = data;
        this.tableData.data.forEach(element => {
          let regex = /\s<|\"\{/
          if (typeof element.eventData === "string") {
            if (element.eventData.match(regex)){
              const index = element.eventData.search(regex);
              // @ts-ignore
              const matchLength = element.eventData.substring(index).match(regex)[0].length;
              element.eventData = [element.eventData.substring(0, Math.min(index, this.metricsService.eventDataUpdateMessageCutLength)), element.eventData.substring(index + matchLength - 2)];
            }
          }
        })
        this.totalPages = data.totalPages;
        this.totalRecords = data.totalRecords;
      }
    });
  }

  onFilter(event: any) {
    this.filters = event.filters;
    this.paginatedRequest.page = 0;
    this.paginatedRequest.request = this.createFilterRequest();
    this.loadFilteredData()
  }

  onSort(event: any) {
    this.paginatedRequest.sortCriteria = {
      field: event.field,
      order: event.order == 1 ? "ASC" : "DESC"
    }
    this.paginatedRequest.page = 0;
    this.loadFilteredData();
  }

  loadFilteredData() {
    this.metricsService.getCuratorMetrics(this.paginatedRequest).subscribe({
        next: data => {
            console.log(data);
            this.tableData = data;
            this.tableData.data.forEach(element => {
              let regex = /\s<|\"\{/
              if (typeof element.eventData === "string") {
                if (element.eventData.match(regex)){
                  const index = element.eventData.search(regex);
                  // @ts-ignore
                  const matchLength = element.eventData.substring(index).match(regex)[0].length;
                  element.eventData = [element.eventData.substring(0, Math.min(index, this.metricsService.eventDataUpdateMessageCutLength)), element.eventData.substring(index + matchLength - 2)];
                }
              }
            })
            this.totalPages = data.totalPages;
            this.totalRecords = data.totalRecords;
        }
    });
  }

  createFilterRequest(): CuratorEventFilter {
    console.log("create filter request " + JSON.stringify(this.filters));
    const filterRequest: CuratorEventFilter = {} as CuratorEventFilter;
    Object.keys(this.filters).forEach(key => {
      const filter = this.filters[key];
      console.log("filter " + JSON.stringify(filter));
      // @ts-ignore
      console.log("filter value " + JSON.stringify(filter[0].value));
      // @ts-ignore
      if (filter && filter[0].value !== null) {
        if (key === 'eventDate') {
          // @ts-ignore
          filterRequest['startDate'] = filter[0].value.start;
          // @ts-ignore
          let endDate = filter[0].value.end;
          if (endDate) {
            endDate = moment(endDate).add(1, 'day').toDate();
          }
          filterRequest['endDate'] = endDate;
        } else {
          // @ts-ignore
          filterRequest[key] = filter[0].value;
          console.log("filter key in if " + key);
          console.log("filter request in if " + JSON.stringify(filterRequest));
        }
      }
    });
    console.log("filter request " + JSON.stringify(filterRequest));
    return filterRequest;
  }

  protected readonly Array = Array;
  protected readonly JSON = JSON;
}

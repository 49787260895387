<ng-container *ngIf="items$ | async as items">
  <p-menubar [ngClass]="{'streaming-mode': currentPath === '/pixelstreaming'}" [model]="items">
    <ng-template pTemplate="start">
      <a class="navbar-no-bg" id="navbar-button-logo" routerLink="/home" [ngClass]="{'block-home': isUserGuest}">
        <img id="navbar-logo" src="/assets/image/logo-black.png"/>
      </a>
    </ng-template>
    <ng-template pTemplate="end">
        <app-navbar-userspace [currentUser]="currentUser" (logout)="logout()"></app-navbar-userspace>
    </ng-template>
  </p-menubar>
</ng-container>

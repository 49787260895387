import {Component, OnInit} from '@angular/core';
import {LoginService} from "../service/login.service";
import {UserService} from "../service/user.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import Validation from "../utils/Validation";
import {map} from "rxjs";
import JwtUtil from "../utils/JwtUtil";
import {NotificationService} from "../service/notification.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {UserEntityResponse} from "../model/UserEntityResponse";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {
  otpEnabled = false
  userId!: string;
  isUserDeleatable = false;

  constructor(private loginService: LoginService, private userService: UserService,
              private fb: FormBuilder, private jwtUtil: JwtUtil, private notificationService: NotificationService,
              private messageService: MessageService, private confirmDialogService: ConfirmationService,
              private translateService: TranslateService, private router: Router) {
    this.loginService.otpEnabled$.subscribe(otpEnabled => this.otpEnabled = otpEnabled);
  }

  updateForm: FormGroup = new FormGroup({
    username: new FormGroup({}),
    email: new FormGroup({}),
    firstName: new FormGroup({}),
    lastName: new FormGroup({}),
    password: new FormGroup({}),
    confirmPassword: new FormGroup({})
  });

  submitted: boolean = false;

  ngOnInit(): void {
    this.userId = this.jwtUtil.getDecodedToken()?.sub || '';
    if (this.userId) {
      this.userService.getUser(this.userId).subscribe({
        next: response => {
          this.updateForm.patchValue({
            email: response.email,
            firstName: response.firstName,
            lastName: response.lastName
          });
        }
      });

      this.isUserDeleatable = !this.userService.currentUser?.realm_access?.roles.includes('admin') &&
        !this.userService.currentUser?.realm_access?.roles.includes('metrics') &&
        !this.userService.currentUser?.realm_access?.roles.includes('guest') && (
          this.userService.currentUser?.realm_access?.roles.includes('user') ||
          this.userService.currentUser?.realm_access?.roles.includes('curator') ||
          false
        );

    }
    this.updateForm = this.fb.group({
      email: ['', Validators.required], // riaggiungere Validators.email quando useremo solo le email come nome utente
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: [''],
      confirmPassword: ['']
      }, {validators: Validation.match('password', 'confirmPassword')}
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.updateForm.invalid) {
      return;
    }
    const username = this.updateForm.value.email;
    const email = this.updateForm.value.email;
    const firstName = this.updateForm.value.firstName;
    const lastName = this.updateForm.value.lastName;
    const password = this.updateForm.value.password;
    this.userService.updateUser(this.userId, email, firstName, lastName, password).subscribe({
      next: response => {
        this.notificationService.message.subscribe(msg => {
          this.messageService.clear();
          this.messageService.add({severity: "success", summary: "Dati aggiornati correttamente."})
        });
        this.updateForm.reset();
      }
    });
  }

  deleteUser() {
    this.confirmDialogService.confirm({
      key: 'deleteSelfUserConfirmDialog',
      accept: () => {
        this.userService.deleteUser(this.userId).subscribe({
          next: () => {
            this.loginService.logout();
            this.router.navigate(['/user-deletion-confirm']);
          },
          error: () => {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError') });
          },
        });
      },
    });
  }

  removeMfa() {
    this.confirmDialogService.confirm({
      key: 'deleteMfaConfirmDialog',
      accept: () => {
        this.userService.deleteAuth().subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: this.translateService.instant('controlPanel.removeMfaSuccess') });
            this.loginService.setOtpEnabled(false);
          },
          error: () => {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError') });
          },
        });
      },
    });
  }
}




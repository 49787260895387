<div class="container-lg mx-auto margin-top-small" id="genetic-container">
  <div class="col-md-6 col-lg-5 mx-auto">
    <div class="title-page title-page-no-margin">
      {{'upload.title'|translate}}
    </div>
    <div id="asset-upload-container">

      <div class="upload-input-field">
        <input pInputText type="text" [(ngModel)]="assetName" name="assetName" id="assetName"
          placeholder="{{'upload.assetName'|translate}}">
        <small id="username-help">Campo obbligatorio</small>
      </div>
      <div [ngClass]="{'hide-element': warnMessages.length === 0}">
        <p-accordion>
          <p-accordionTab headerStyleClass="accordion-custom-header" [selected]="false">
            <ng-template pTemplate="header">
              <div class="flex align-items-center">
                <i class="pi pi-exclamation-triangle"></i>
                <span [style]="{'margin-left': '0.5em'}" class="vertical-align-middle">{{'upload.details'|translate}}</span>
              </div>
            </ng-template>
            <p-messages
              key="upload-mesh-tip"
              [closable]="false" />
          </p-accordionTab>
        </p-accordion>
      </div>
      <p-fileUpload #fileUploader name="files[]" (onSelect)="onFileSelected($event)" [showUploadButton]="false"
        chooseLabel="{{'upload.chooseLabel'|translate}}" cancelLabel="{{'upload.cancelLabel'|translate}}"
        (onRemove)="onFileRemoved()" (onClear)="onFileRemoved()" [accept]="ACCEPT_FILE_TYPE"
        [maxFileSize]="MAX_DIMENSION_MB * 1024 * 1024" invalidFileSizeMessageSummary=""
        invalidFileSizeMessageDetail="{{ 'upload.invalidFileSizeError' | translate: { dimension: MAX_DIMENSION_MB } }}"
        invalidFileTypeMessageSummary=""
        invalidFileTypeMessageDetail="{{ 'upload.invalidFileTypeError' | translate: { types: ACCEPT_FILE_TYPE } }}">
        <ng-template pTemplate="file" let-file>
          <span *ngIf="selectedFile" i class="file-name">{{ file.name }}</span>
          <span *ngIf="selectedFile" class="file-size">{{file.size / 1024 / 1024| number:'1.0-2'}} MB</span>
          <div *ngIf="selectedFile" class="p-fileupload-cancel">
            <button type="button" pButton icon="pi pi-times" pButton type="button" icon="pi pi-times"
                    (click)="onFileRemoved()" class="p-button-text"></button>
          </div>
          <div class="file-tags" *ngIf="warnMessages.length > 0">
            <p-tag [style]="{'margin': '0.2em'}" *ngFor="let msg of warnTags" [value]="msg.detail" [severity]="'warning'">
              <i [style]="{'margin-right': '0.5em'}" class="pi pi-exclamation-triangle"></i>
            </p-tag>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div *ngIf="!selectedFile" class="asset-upload-content-icon">
          </div>
        </ng-template>
      </p-fileUpload>
      <div class="upload-section">
        <p-progressBar [ngStyle]="{'visibility': isLoading ? 'visible' : 'hidden' }" [value]="progress"
          max="100"></p-progressBar>
        <p-button class="upload-button" icon="pi pi-cloud-upload" [disabled]="!this.selectedFile || !assetName"
          (onClick)="onUpload()">{{'upload.uploadLabel'|translate}}</p-button>
      </div>

      <p-button [link]="true" routerLink="/" class="mt-2 d-block"
        id="back-home">{{'upload.backHome'|translate}}</p-button>

    </div>
  </div>
</div>

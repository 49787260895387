import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import JwtUtil from '../utils/JwtUtil';
import { environment } from 'src/environments/environment';
import { SKIP_LOADER_SPINNER } from '../interceptor/HttpContext';
import { Observable, map } from 'rxjs';

export interface StatisticsExport {
  processedRecords: number;
  totalRecords: number;
  exportId: string;
  started: boolean;
  completed: boolean;
  fileName: string;
  errorMessages: any[];
  hasErrors: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StatisticsExportService {
  private httpClient: HttpClient = inject(HttpClient);
  private jwtUtil: JwtUtil = inject(JwtUtil);
  realmId: string | undefined;
  apiUrl = '';

  exportProjectId = environment.exportProjectId;
  exportTypeIds = environment.exportTypeIds;

  constructor() {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`
    this.realmId = this.jwtUtil.getDecodedToken()?.realm_id;
  }

  postStatisticsExport(exportType: string, dateStart: Date | number, dateStop: Date | number): Observable<StatisticsExport> {
    const rowKeyLowerBound = (new Date(dateStart)).getTime();
    const rowKeyUpperBound = (new Date(dateStop)).getTime();
    const exportTypeId = (this.exportTypeIds as any)[exportType] || 0;
    return this.httpClient.post<StatisticsExport>(
      this.apiUrl + '/api/v1/metrics/hprojects/' + this.exportProjectId + '/hpacket/' + exportTypeId + '/export',
      { },
      { params: { rowKeyLowerBound, rowKeyUpperBound, prettyTimestamp: true, exportName: '' } }
    );
  }

  getStatisticsExportStatus(token: string) {
    const context = new HttpContext().set(SKIP_LOADER_SPINNER, true);
    return this.httpClient.get<StatisticsExport>(this.apiUrl + '/api/v1/metrics/hprojects/export/' + token, { context });
  }

  downloadStatisticsExport(token: string) {
    return this.httpClient.get(this.apiUrl + '/api/v1/metrics/hprojects/export/download/' + token, { responseType: 'blob' });
  }
}

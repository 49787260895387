<div id="login-container">
  <div id="login-header">
    <a href=""><img [src]="'/assets/image/logo-black.png'"></a>
  </div>
  <div class="container-lg">
    <div class="form-container col-md-6 col-lg-5 mx-auto">
      <p-card header="{{ 'updatePassword.title' | translate }}">
        <form [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()">
          <div class="card-body">
            <div class="field">
              <label for="password">{{ 'password' | translate }}</label>
              <input type="password" placeholder="{{ 'password' | translate }}" id="password" pInputText formControlName="password">
              <small class="p-error block" *ngIf="
                updatePasswordForm.get('password')?.invalid && updatePasswordForm.get('password')?.dirty
              ">{{ 'requiredField' | translate }}</small>
            </div>

            <div class="field">
              <label for="confirmPassword">{{ 'confirmPassword' | translate }}</label>
              <input type="password" placeholder="{{ 'confirmPassword' | translate }}" id="confirmPassword" pInputText formControlName="confirmPassword">
              <small class="p-error block" *ngIf="
                updatePasswordForm.get('confirmPassword')?.invalid && updatePasswordForm.get('confirmPassword')?.dirty
              ">{{ 'invalidConfirmPassword' | translate }}</small>
            </div>

            <div class="d-flex justify-content-end">
              <p-button type="submit" label="{{ 'update' | translate }}" [disabled]="updatePasswordForm.invalid"></p-button>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StatisticsExportService } from './statistics-export.service';
import { repeat, skipWhile, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-statistics-export',
  templateUrl: './statistics-export.component.html',
  styleUrls: ['./statistics-export.component.scss']
})
export class StatisticsExportComponent implements OnInit {

  MIN_DATE = new Date('2024-06-01T00:00:00.000');
  reportTypeList = ['exhibition', 'asset', 'room', 'attachment']; // link

  reportForm: FormGroup = new FormGroup({
    timeStart: new FormControl(null, Validators.required),
    timeStop: new FormControl(null, Validators.required),
    exportType: new FormControl([], Validators.required),
  });

  exportRow: {
    token: string | null;
    timeFilter: { start: Date; end: Date; };
    type: string;
    status: string;
  } | null = null;

  constructor(
    private statisticsExportService: StatisticsExportService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    const currentExportTaskJson = localStorage.getItem('export-task');
    if (currentExportTaskJson) {
      const currentExportTask = JSON.parse(currentExportTaskJson);
      this.exportRow = {
        token: currentExportTask.token,
        timeFilter: currentExportTask.timeFilter,
        type: currentExportTask.type,
        status: 'pending',
      };
      this.updateExport();
    }
  }

  onSubmit() {
    this.messageService.clear();
    this.exportRow =  null;
    if (this.reportForm.value.timeStop < this.reportForm.value.timeStart) {
      this.messageService.add({severity: 'error', summary: this.translateService.instant('export.dateError'), key: 'statisticsExportError'});
      return;
    }
    this.statisticsExportService.postStatisticsExport(
      this.reportForm.value.exportType,
      this.reportForm.value.timeStart,
      this.reportForm.value.timeStop,
    ).subscribe({
      next: res => {
        this.exportRow = {
          token: res.exportId,
          type: this.reportForm.value.exportType,
          timeFilter: { start: this.reportForm.value.timeStart, end: this.reportForm.value.timeStop },
          status: 'pending',
        };
        localStorage.setItem('export-task', JSON.stringify({
          token: this.exportRow.token,
          type: this.exportRow.type,
          timeFilter: this.exportRow.timeFilter,
        }));
        this.updateExport();
      },
      error: err => {
        this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
        this.messageService.add({severity: 'error', summary: this.translateService.instant('export.genericError'), key: 'statisticsExportError'});
      }
    });
  }

  updateExport() {
    if (!this.exportRow || !this.exportRow.token) {
      return;
    }

    const statusRequest = () => this.statisticsExportService.getStatisticsExportStatus(this.exportRow!.token!);

    statusRequest().pipe(
      repeat({ delay: 3000 }),
      skipWhile((response) => !response.completed),
      take(1),
    ).subscribe({
      next: res => {
        this.exportRow!.status = res.hasErrors ? 'error' : 'ready';
      },
      error: err => {
        this.exportRow!.status = 'error';
      }
    }
    );
  }

  downloadFile() {
    this.statisticsExportService.downloadStatisticsExport(this.exportRow!.token!).subscribe({
      next: res => {
        saveAs(res, this.exportRow!.type + '.csv');
        this.exportRow = null;
        localStorage.removeItem('export-task');
      },
      error: err => {
        this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
      }
    });
  }

}

import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { UserService } from '../service/user.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import Validation from "../utils/Validation";
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { LastActivePageService } from '../service/last-active-page.service';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit{

  route = inject(ActivatedRoute);
  userService = inject(UserService);
  formBuilder = inject(FormBuilder);
  messageService = inject(MessageService);
  translateService = inject(TranslateService);
  lastActivePageService = inject(LastActivePageService);
  loginService = inject(LoginService);
  router = inject(Router);
  updatePasswordForm: FormGroup = new FormGroup({
    password: new FormControl(''),
    confirmPassword: new FormControl('')
  });

  ngOnInit(): void {
    this.updatePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {validators: Validation.match('password', 'confirmPassword')});
  }

  onSubmit(): void {
    let token = this.route.snapshot.paramMap.get('token');
    const password = this.updatePasswordForm.value.password || '';
    if (token) {
      this.userService.updatePassword(token, password).subscribe({
        next: (data) => {
          this.messageService.clear();
          this.messageService.add({severity: 'success', summary: this.translateService.instant('updatePassword.success')});
          this.router.navigate(['/login']).then(succeeded => {
            if (succeeded) {
              this.lastActivePageService.storeLastActivePage('/');
              this.loginService.logout();
            }
          });
        },
        error: err => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
        }
      });
    }
  }
}

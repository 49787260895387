import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeFilterComponent),
    multi: true,
  }],
})
export class TimeFilterComponent implements ControlValueAccessor {

  @Input() styleClass = '';

  dateFilter: { start: Date | null; end: Date| null } = { start: null, end: null};

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: { start: Date | null; end: Date| null } | null) {
    this.dateFilter = value ? {...value} : { start: null, end: null};
    
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  dateChanged() {
    this.onChange(this.dateFilter);
  }
}

<div id="login-container">
  <div class="container-lg">
    <div class="form-container col-md-6 col-lg-5 mx-auto">
      <p-card header="{{(isAddUser ? 'signUp.title1' : 'signUp.title2') | translate}}" class="w-100">
          <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="card-body">
            <div class="field">
              <label for="firstName">{{'firstName'|translate}}</label>
              <input type="text" placeholder="{{'firstName'|translate}}" id="firstName" pInputText formControlName="firstName">
              <small class="p-error block" *ngIf="
                  signupForm.get('firstName')?.invalid && signupForm.get('firstName')?.dirty
                ">{{'requiredField'|translate}}</small>
            </div>

            <div class="field">
              <label for="lastName">{{'lastName'|translate}}</label>
              <input type="text" placeholder="{{'lastName'|translate}}" id="lastName" pInputText formControlName="lastName">
              <small class="p-error block" *ngIf="
                  signupForm.get('lastName')?.invalid && signupForm.get('lastName')?.dirty
                ">{{'requiredField'|translate}}</small>
            </div>

            <div class="field">
              <label for="email">{{'email'|translate}}</label>
              <input type="email" placeholder="{{'email'|translate}}" id="email" pInputText formControlName="email" autocomplete="off">
              <small class="p-error block" *ngIf="
                  signupForm.get('email')?.invalid && signupForm.get('email')?.dirty
                ">{{'requiredField'|translate}}</small>
            </div>

            <div class="field">
              <label for="password">{{'password'|translate}}</label>
              <input type="text" id="password" pInputText formControlName="password" autocomplete="off">
              <small class="p-error block" *ngIf="
                  signupForm.get('password')?.invalid && signupForm.get('password')?.dirty
                ">{{'requiredField'|translate}}</small>
            </div>

            <div class="field">
              <label for="confirmPassword">{{'confirmPassword'|translate}}</label>
              <input type="text" id="confirmPassword" pInputText formControlName="confirmPassword" autocomplete="off">
              <small class="p-error block" *ngIf="
                  signupForm.get('confirmPassword')?.invalid && signupForm.get('confirmPassword')?.dirty
                ">{{'invalidConfirmPassword'|translate}}</small>
            </div>

            <div class="mt-4 d-flex justify-content-between">
              <p-button type="button" label="{{'cancel'|translate}}" [routerLink]="isAddUser ? '/admin-control-panel' : '/login'" class="p-button-light"></p-button>
              <p-button type="submit" label="{{'save'|translate}}" [disabled]="signupForm.invalid"></p-button>
            </div>
          </form>
      </p-card>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { StatisticsAsset, StatisticsAssetList } from '../Statistics.model';
import { StatisticsService } from '../statistics.service';
import { MetricTimePipe } from '../timePipe.pipe';
import { generateCsv, download } from 'export-to-csv';
import * as moment from 'moment';

@Component({
  selector: 'app-statistics-assets',
  templateUrl: './statistics-assets.component.html',
  styleUrls: ['./statistics-assets.component.scss']
})
export class StatisticsAssetsComponent implements OnInit {

  pageRows = 5;
  pageFirst = 0;
  sortField = '';
  sortOrder = 0;
  chartData = {
    chartData: { },
    chartOptions: { },
  };

  requestError = false;
  statisticsAssetsColumns = [
    { field: 'assetId', header: 'ID Asset' },
    { field: 'assetName', header: 'Nome Asset' },
    { field: 'totalVisits', header: 'Conteggio Ispezioni' },
    { field: 'averageVisitDuration', header: 'Durata Media Ispezione'},
  ];
  statisticsAssetsData: StatisticsAssetList = [];
  statisticsAssetsDataToShow: StatisticsAssetList = [];

  constructor(
    private statisticsService: StatisticsService,
    private metricTimePipe: MetricTimePipe,
  ) { }

  ngOnInit(): void {
    this.statisticsService.getAssetStatistics().subscribe({
      next: res => {
        this.statisticsAssetsData = res;
        this.setDataToShow();
      },
      error: err => {
        this.requestError = true;
      }
    });
  }

  setDataToShow() {
    if (this.sortOrder !== 0) {
      this.statisticsAssetsData = this.statisticsAssetsData.sort(
        (x1, x2) => this.sortOrder * (x1[this.sortField  as keyof StatisticsAsset] < x2[this.sortField  as keyof StatisticsAsset] ? -1 : 1),
      );
    }
    this.statisticsAssetsDataToShow = this.statisticsAssetsData.slice(this.pageFirst, this.pageFirst + this.pageRows);
    this.initChartData();
  }

  initChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const valueColor = documentStyle.getPropertyValue('--primary-color-bg');
    const valueColor1 = documentStyle.getPropertyValue('--text-color');
    this.chartData = {
      chartData: {
        labels: this.statisticsAssetsDataToShow.map(x => x.assetName),
        datasets: [
          {
            label: 'Conteggio Ispezioni',
            backgroundColor: valueColor,
            yAxisID: 'y',
            data: this.statisticsAssetsDataToShow.map(x => x.totalVisits),
          },
          {
            label: 'Durata media Ispezioni',
            backgroundColor: valueColor1,
            yAxisID: 'y2',
            data: this.statisticsAssetsDataToShow.map(x => x.averageVisitDuration),
          },
        ],
      },
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                if (context.dataset.yAxisID === 'y2') {
                  return context.dataset.label + ': ' + this.metricTimePipe.transform(context.parsed.y * 60 * 1000) ;
                } else {
                  return context.dataset.label + ': ' + context.parsed.y;
                }
              },
            },
          },
          legend: {
            labels: {
              color: valueColor1,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: valueColor1,
            },
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              color: valueColor,
            },
          },
          y2: {
            position: 'right',
            ticks: {
              color: valueColor1,
              callback: (value: any) => this.metricTimePipe.transform(value * 60 * 1000 ),
            },
          },
  
        },
      },
    };
  
  }

  changePage(event: any) {
    this.pageFirst = event.first;
    this.pageRows = event.rows;
    this.setDataToShow();
  }

  sortData(sortEvent: any) {
    if (sortEvent.field === this.sortField && sortEvent.order === this.sortOrder) { // prevent infinite loop
      return;
    }
    this.sortField = sortEvent.field;
    this.sortOrder = sortEvent.order;
    this.setDataToShow();
  }

  exportCsv() {
    const csvConfig = { useKeysAsHeaders: true, filename: 'Assets_' + moment().format('YYYYMMDD')};
    const csv = generateCsv(csvConfig)(this.statisticsAssetsData as any);
    download(csvConfig)(csv);
  }

}

import { Component } from '@angular/core';
import JwtUtil from "../utils/JwtUtil";
import {UserService} from "../service/user.service";
import {AuthenticatorResponse} from "../model/AuthenticatorResponse";
import {Router} from "@angular/router";
import { LoginService } from '../service/login.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss']
})
export class MfaComponent {
  authenticatorResponse: AuthenticatorResponse = {} as AuthenticatorResponse;
  constructor(
    private jwtUtil: JwtUtil,
    private userService: UserService,
    private router: Router,
    private loginService: LoginService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    console.log(jwtUtil.getDecodedToken()?.sub);
    userService.getAuthSecret().subscribe({
      next: (data: AuthenticatorResponse) => {
        console.log(data);
        this.authenticatorResponse = data}
    });
  }

  saveAuthConfirm() {
    this.confirmationService.confirm({
        key: 'confirmMfaDialog',
        accept: () => this.saveAuth(),
    });
  }

  saveAuth() {
    this.userService.saveAuth(this.authenticatorResponse.authCode).subscribe({
      next: data => {
        console.log(data);
        this.loginService.setOtpEnabled(true);
        this.router.navigate(['/']);
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: this.translateService.instant('mfa.success')});
      },
      error: err => {
        this.messageService.clear();
        this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
      }
    });
  }

}

<div class="container-lg">
  <p-messages key="userLoadError" [closable]="false" />

  <div class="form-container col-md-6 col-lg-5 mx-auto">

    <p-card *ngIf="user">
      <div class="card-body">

        <p-card header="{{'editUser.title1'|translate}}">
          <form [formGroup]="userForm" (ngSubmit)="onUserSubmit()">
            <div class="card-body">
              <div class="field">
                <label for="email">{{'email'|translate}}</label>
                <input type="email" placeholder="{{'email'|translate}}" id="email" pInputText formControlName="email">
              </div>
              <div class="field">
                <label for="firstName">{{'firstName'|translate}}</label>
                <input type="text" placeholder="{{'firstName'|translate}}" id="firstName" pInputText formControlName="firstName">
              </div>
              <div class="field">
                <label for="lastName">{{'lastName'|translate}}</label>
                <input type="text" placeholder="{{'lastName'|translate}}" id="lastName" pInputText formControlName="lastName">
              </div>
              <div class="d-flex justify-content-end">
                <p-button type="submit" label="{{'save'|translate}}" [disabled]="userForm.invalid"></p-button>
              </div>
            </div>
          </form>
        </p-card>

        <hr>

        <p-card header="{{'editUser.title2'|translate}}">
          <div class="card-body">

            <div class="field">
              <label for="activeSwitch">{{'role'|translate}}</label>
              <p-dropdown
                styleClass="edit-user-dropdown"
                [options]="userRoleList"
                [(ngModel)]="userRole"
                (onChange)="onUserRoleSubmit()"
                optionLabel="label"
                optionValue="value">
                <ng-template let-role pTemplate="selectedItem">
                  {{ role.label | translate }}
                </ng-template>
                <ng-template let-role pTemplate="item">
                  {{ role.label | translate }}
                </ng-template>
              </p-dropdown>
            </div>

            <div class="field">
              <label for="activeSwitch">{{'activation'|translate}}</label>
              <p-dropdown
                styleClass="edit-user-dropdown"
                [options]="userActivationList"
                [(ngModel)]="userActivation"
                (onChange)="onUserActivationSubmit()"
                optionLabel="label"
                optionValue="value">
                <ng-template let-active pTemplate="selectedItem">
                  {{ active.label | translate }}
                </ng-template>
                <ng-template let-active pTemplate="item">
                  {{ active.label | translate }}
                </ng-template>
              </p-dropdown>
            </div>

          </div>
        </p-card>

        <hr>

        <p-card>
          <div class="card-body">
            <p-button *ngIf="userRole === 'user' || userRole === 'curator'" (onClick)="sendResetPasswordRequest()"
              label="{{'editUser.sendResetPasswordRequest'|translate}}" [disabled]="!user"></p-button>
            <p-button *ngIf="userRole === 'user' || userRole === 'curator'" (onClick)="deleteUser()"
              label="{{'editUser.deleteUser'|translate}}"></p-button>
            <a [routerLink]="'/admin-control-panel'">
              <p-button label="{{'back'|translate}}"></p-button>
            </a>
          </div>
        </p-card>

      </div>
    </p-card>

  </div>
</div>


<p-confirmDialog key="deleteUserConfirmDialog" acceptButtonStyleClass="confirm-button" rejectButtonStyleClass="confirm-button light" />
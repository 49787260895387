import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Base64Service {

  private bytesToBase64(bytes: any) {
    const binString = Array.from(bytes, (byte) =>
      String.fromCodePoint(byte as any),
    ).join('');
    return btoa(binString);
  }

  private base64ToBytes(base64: string) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => (m as any).codePointAt(0));
  }

  stringToBase64(text: string) {
    const bytes = new TextEncoder().encode(text);
    return this.bytesToBase64(bytes);
  }

  base64ToString(base64: string) {
    const bytes = this.base64ToBytes(base64);
    return new TextDecoder().decode(bytes);
  }

}

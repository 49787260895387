import {Component, OnInit} from '@angular/core';
import {AssetService} from "../service/asset.service";
import {PaginatedRequest} from "../model/PaginatedRequest";
import {CuratorEventFilter} from "../model/CuratorEventFilter";
import {SortCriteria} from "../service/metrics.service";
import {SafeHtml} from "@angular/platform-browser";
import {PaginatedResponse} from "../model/PaginatedResponse";
import {CuratorEventResponse} from "../model/CuratorEventResponse";
import {AssetResponse} from "../model/AssetResponse";
import {TablePageEvent} from "primeng/table";

@Component({
  selector: 'app-asset-manager',
  templateUrl: './asset-manager.component.html',
  styleUrls: ['./asset-manager.component.scss']
})
export class AssetManagerComponent implements OnInit {
  assets: AssetResponse[] = [];
  tableData: PaginatedResponse<AssetResponse> = {
    currentPage: 0,
    data: [] as AssetResponse[],
    totalPages: 0,
    totalRecords: 0
  }
  pageSize = 25;
  page = 0;
  totalPages = 0;
  totalRecords: number = 100;
  curatorEventColumns: any[] = [];
  globalFilterFields: string[] = [];
  paginatedRequest: PaginatedRequest<CuratorEventFilter> & SortCriteria = {
    page: this.page,
    pageSize: this.pageSize,
    request: {} as CuratorEventFilter
  };
  displayModal: boolean = false;
  modalContent: SafeHtml = '';

  entityTypeOptions = [ 'ASSET', 'EXHIBITION' ];
  eventTypeOptions = [ 'CREATE', 'DELETE', 'UPDATE' ];

  constructor(private assetService: AssetService) {
  }

  ngOnInit(): void {
    this.assetService.getAllAssets().subscribe(data => {
      this.assets = data;
      this.tableData.data = data;
      this.loadTable();
      console.log(this.assets);
    });
  }

  loadTable() {
    this.globalFilterFields = this.tableData.data.length > 0 ? Object.keys(this.tableData.data[0]) : []
    this.curatorEventColumns = [
      { field: 'id', header: 'id' },
      { field: 'name', header: 'entityName' },
      { field: 'resourcePath', header: 'resourcePath' },
      { field: 'createDate', header: 'createDate' },
      { field: 'modifiedDate', header: 'modifiedDate' },
    ];
  }

  deleteAsset(id: string) {
    this.assetService.deleteAsset(id).subscribe(data => {
      console.log('Removed item: ', data);
    });
  }
}

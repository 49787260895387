import * as jwt_decode from 'jwt-decode';
import { LoginService } from '../service/login.service';
import {BehaviorSubject, map} from "rxjs";
import {Injectable} from "@angular/core";
import {JwtToken} from "../model/JwtToken";
import {JwtPayload} from "jwt-decode";
import {ExtendedJwtPayload} from "../model/ExtendedJwtPayload";

@Injectable({
  providedIn: 'root'
})
export default class JwtUtil {

  decodedToken$: BehaviorSubject<ExtendedJwtPayload | null>;

  constructor(private loginService: LoginService) {
    this.decodedToken$ = new BehaviorSubject<ExtendedJwtPayload | null>(null);
    this.loginService.token$.pipe(
      map(token => token ? jwt_decode.jwtDecode(token) : null)
    ).subscribe(this.decodedToken$);
  }

  getDecodedToken() {
    return this.decodedToken$.getValue();
  }
}

<div class="row">
  <div class="col-lg-9 mx-auto">
    <div class="row">
      <ng-container *ngFor="let item of menu">
        <div routerLink="{{item.routerLink}}" class="col-md-6 mb-4" *ngIf="item.visible">
          <div  class="menu-item d-flex align-items-center">
            <div class="menu-element d-flex align-items-center justify-content-center">
              <img [src]="'/assets/icons/' + item.icon">
            </div>
            <div>
              <dt><a routerLink="{{item.routerLink}}">{{item.label!|translate}}</a></dt>
              <dd>{{item.description!|translate}}</dd>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="streaming-debug-overlay">
  <span>Debug level: {{debugLevel}}</span>
  <span>Resolution: {{getResolution(debugData!.frameHeight)}}</span>
  <span>Bitrate: {{debugData?.bitrate}}</span>
  <span [ngClass]="getJitterClass(debugData?.jitter)">Jitter: {{debugData?.jitter}}</span>
  <span *ngIf="userRoles.includes('admin') && debugLevel > 0">Packet Loss: {{debugData?.packetLost}}</span>
  <app-fps-indicator [debugLevel]="debugLevel" [fps]="debugData!.fps"></app-fps-indicator>
  <span *ngIf="userRoles.includes('admin') && debugLevel > 0">Freeze counter: {{debugData?.freeze}}</span>
  <span *ngIf="userRoles.includes('admin') && debugLevel > 0">Freeze time: {{debugData?.totalFreezeDuration}}</span>
</div>

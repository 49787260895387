import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageSelectModel} from "../../model/LangDataModel";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit{

  languages: LanguageSelectModel[] | undefined = [];
  selectedLang: LanguageSelectModel | undefined

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    console.log(this.translate.getLangs());
    const aryLangs = this.translate.getLangs();
    if (aryLangs.length > 0) {
      console.log(aryLangs, aryLangs.length);
      aryLangs.map(lang => this.languages?.push({name: lang, code: lang}));
      console.log(aryLangs, aryLangs.length, this.languages);
    }
    this.selectedLang = {name: this.translate.currentLang, code: this.translate.currentLang};
    console.log('Language selected and languages: ', this.selectedLang, this.languages);
  }
  changeLanguage() {
    const language = this.selectedLang?.code || this.translate.getDefaultLang();
    this.translate.use(language);
    localStorage.setItem('lang', language);
    console.log(`Set language: ${this.selectedLang?.code}`);
  }

}

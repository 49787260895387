import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _isVisible = new BehaviorSubject<boolean>(false);
  private _message = new BehaviorSubject<string>('');

  isVisible = this._isVisible.asObservable();
  message = this._message.asObservable();

  showDialog(message: string) {
    this._isVisible.next(true);
    this._message.next(message);
  }

  hideDialog() {
    this._isVisible.next(false);
  }
}

<div class="container-lg" id="mfa">
  <div class="form-container col-md-6 col-lg-5 mx-auto">
    <p-card header="{{ 'mfa.title' | translate }}">
      <!--<p>{{authenticatorResponse.authCode}}</p>-->
      <p>
        {{ 'mfa.description' | translate }}
      </p>
      <p class="mt-5">
        {{ 'mfa.step1' | translate }}
      </p>
      <div class="d-flex align-items-center app-store mb-3 flex-wrap">
        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=it&gl=US&pli=1" target="_blank">
          <img [src]="'/assets/image/google-play.png'">
        </a>
        <a href="https://apps.apple.com/it/app/google-authenticator/id388497605" target="_blank">
          <img [src]="'/assets/image/apple-store.png'">
        </a>
      </div>
      <p class="mt-5">
        {{ 'mfa.step2' | translate }}
      </p>
      <qrcode [qrdata]="authenticatorResponse.authUrl"></qrcode>
      <p class="mt-5">
        {{ 'mfa.step3' | translate }}
      </p>
      <div class="d-flex gap-3 justify-content-between align-items-center">
          <button onclick="location.href='/controlpanel'" class="p-button p-button-light">{{ 'cancel' | translate }}</button>
          <button (click)="saveAuthConfirm()" class="p-button">{{ 'save' | translate }}</button>
      </div>
    </p-card>
  </div>
</div>

<p-confirmDialog key="confirmMfaDialog" acceptButtonStyleClass="confirm-button"
  rejectButtonStyleClass="confirm-button light" message="{{ 'mfa.confirmDescription' | translate}}"
  header="{{ 'mfa.confirmHeader' | translate}}" icon="pi pi-exclamation-triangle"
  rejectLabel="{{ 'cancel' | translate }}" acceptLabel="OK" />

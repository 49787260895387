<div class="container-lg">
  <div class="form-container mx-auto d-block">
    <div class="title-page">
      <span>{{'adminControlPanel.title'|translate}}</span>
      <a [routerLink]="['add-user']">
        <p-button
          pRipple
          icon="pi pi-plus"
          styleClass="icon-button" />
      </a>
    </div>
    <p-table #dt2 [lazy]="true" [lazyLoadOnInit]="true" [value]="users" [tableStyle]="{ 'width': '100%' }"
             [columns]="userColumns" class="table-custom users" styleClass="p-datatable-gridlines p-datatable-sm p-datatable-striped"
            [paginator]="true" [rows]="pageSize" [totalRecords]="totalRecords" [globalFilterFields]="" [rowsPerPageOptions]="[1, 5, 10, 20]"
             [showCurrentPageReport]="true" (onPage)="onPageChange($event)" (onFilter)="onFilter($event)" (onSort)="onSort($event)"

    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th *ngIf="col.field !== 'actions'" [pSortableColumn]="col.field">
              {{col.header|translate}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
              <p-columnFilter *ngIf="col.field === 'id'" type="text" field="id" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu"></p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'email'" type="text" field="email" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu" class="email"></p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'firstName'" type="text" field="firstName" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu" class="firstName"></p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'lastName'" type="text" field="lastName" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu" class="lastName"></p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'enabled'" type="text" field="enabled" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu" class="enabled"></p-columnFilter>
            </th>
            <th *ngIf="col.field === 'actions'">
              {{col.header|translate}}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <ng-container *ngIf="col.field === 'id' || col.field === 'email' || col.field === 'firstName' || col.field === 'lastName'">
              {{rowData[col.field]}}
            </ng-container>
            <ng-container *ngIf="col.field === 'role'">
              {{('roles.'+rowData[col.field])|translate}}
            </ng-container>
            <ng-container *ngIf="col.field === 'enabled'">
              {{('activationStatus.table.'+rowData[col.field])|translate}}
            </ng-container>
            <ng-container *ngIf="col.field === 'actions' && (rowData.role === 'curator' || rowData.role === 'user')">
              <a [routerLink]="[rowData.id]">
                <p-button
                  pRipple
                  icon="pi pi-pencil"
                  styleClass="icon-button" />
              </a>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

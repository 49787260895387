import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {UserGroupResponse} from "../model/UserGroupResponse";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  apiUrl = "";

  constructor(private httpClient: HttpClient) {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`
  }

  getAllAssets(): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + '/api/v2/ms/assets');
  }
  deleteAsset(assetId: string): Observable<any> {
    return this.httpClient.delete<any>(this.apiUrl + '/api/v2/ms/assets/' + assetId);
  }
}

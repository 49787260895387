import { Injectable } from '@angular/core';
import { AllSettings } from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.3';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ConfigParams} from "@epicgames-ps/lib-pixelstreamingfrontend-ue5.3/types/Config/Config";

@Injectable({
  providedIn: 'root'
})
export class PixelStreamingService {

  private settingsSource = new BehaviorSubject<Partial<ConfigParams>>({});
  currentSettings = this.settingsSource.asObservable();
  private wsUrl = new Subject<string>();
  wsUrl$ = this.wsUrl.asObservable();
  //psVideoInitialized = new Subject<boolean>();

  public isDisconnected = false;
  public showTutorial: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  changeSettings(settings: ConfigParams) {
    console.log("changing settings")
    console.log(settings)
    this.settingsSource.next(settings);
  }

  getSettings(): Observable<ConfigParams> {
    return this.settingsSource;
  }

  setWsUrl(url: string) {
    this.wsUrl.next(url);
  }

}

import { Injectable } from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {SignalingServerResponse} from "../model/SignalingServerResponse";
import {environment} from "../../environments/environment";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {MatchmackerStatusModel} from "../model/MatchmakerStatusResponse";

@Injectable({
  providedIn: 'root'
})
export class SystemStatusService {

  private eventSource: EventSource | null = null;

  constructor(private http: HttpClient, private httpB: HttpBackend) { }
  status: Subject<MatchmackerStatusModel> = new Subject<MatchmackerStatusModel>();

  getStatus(): Observable<MatchmackerStatusModel> {
    const httpClient = new HttpClient(this.httpB);
    if (environment.matchmakingEnable) {
      return httpClient.get<MatchmackerStatusModel>(environment.matchmakingDomain + '/sse/status');
    } else {
      return of(<MatchmackerStatusModel>{"ServersFree": 0,"ServersLocked": 0,"Servers": 0});
    }
  }

  updateStatus() {
    this.getStatus().subscribe(status => this.status.next(status));
  }

  getSseStatus(): Observable<MatchmackerStatusModel> {
    return new Observable(observer => {
      if (!environment.matchmakingEnable) {
        observer.next({ "ServersFree": 0, "ServersLocked": 0, "Servers": 0, QueuedInstances: 0, UsersInQueue: 0});
        observer.complete();
        return;
      }

      this.eventSource = new EventSource(environment.matchmakingDomain + '/sse/status');

      this.eventSource.onmessage = event => {
        const data: MatchmackerStatusModel = JSON.parse(event.data);
        observer.next(data);
      };

      this.eventSource.onerror = error => {
        observer.error(error);
        this.closeEventSource();
      };

      return () => this.closeEventSource();
    });
  }

  closeEventSource() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }
}

<div class="container-lg full-width">
  <div class="form-container mx-auto d-block">
    <div class="title-page">
      {{'logs.title'|translate}}
    </div>
    <ng-container *ngIf="tableData">
      <p-table #dt1
               [value]="tableData.data" [tableStyle]="{ 'width': '100%' }" [columns]="curatorEventColumns" class="table-custom" [paginator]="true"
               [rows]="pageSize" [totalRecords]="totalRecords"
               [rowsPerPageOptions]="[5,10,20,50]"
               [resizableColumns]="true" [reorderableColumns]="true"
               columnResizeMode="expand" [showCurrentPageReport]="true" styleClass="p-datatable-gridlines p-datatable-sm p-datatable-striped"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header|translate}}
            </th>
            <th>
              Actions
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              <ng-container *ngIf="col.field === 'createdDate'">
                {{ rowData[col.field] | date:"dd/MM/YYYY HH:mm:ss":'UTC +4' }} <!-- temp fix (remove UTC +4 after BE fix)-->
              </ng-container>
              <ng-container *ngIf="col.field === 'modifiedDate'">
                {{ rowData[col.field] | date:"dd/MM/YYYY HH:mm:ss":'UTC +4' }} <!-- temp fix (remove UTC +4 after BE fix)-->
              </ng-container>
              <ng-container *ngIf="col.field !== 'eventData' && col.field !== 'eventDate'">
                {{ rowData[col.field] }}
              </ng-container>
            </td>
            <td>
              <p-button
                pRipple
                icon="pi pi-trash"
                styleClass="icon-button" (onClick)="deleteAsset(rowData.id)" />
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>

    <p-dialog header="{{'logs.configChanges'|translate}}" [(visible)]="displayModal" [draggable]="false" [blockScroll]="true">
      <pre #modalContentText [innerHTML]="modalContent"></pre>
    </p-dialog>
  </div>
</div>

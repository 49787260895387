import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from "primeng/api";
import {LoginService} from "../service/login.service";
import {map, Observable, startWith} from "rxjs";
import {UserService} from "../service/user.service";
import {ActivatedRoute, NavigationEnd, Router, RouterEvent, Scroll} from "@angular/router";
import { LastActivePageService } from '../service/last-active-page.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit{
  isLoggedIn$!: Observable<boolean>;
  items$!: Observable<MenuItem[]>;
  currentUser: any;
  isUserGuest = true;
  currentPath = '';
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    private lastActivePageService: LastActivePageService,
  ) { }
  protected items!: MenuItem[];
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.url;
      }
      if (event instanceof Scroll) {
        this.currentPath = event.routerEvent.url;
      }
    });
    // getting user and role. Subscription to user change should not be necessary since the navbar is instantiated after each login once the user has been reset
    this.currentUser = this.userService.getCurrentUser();
    this.isUserGuest = this.currentUser?.realm_access?.roles.includes('guest');

    this.isLoggedIn$ = this.loginService.isAuthenticatedUser();
    this.items$ = this.isLoggedIn$.pipe(
      map(isLoggedIn => [
/*        {
          label: 'Home',
          routerLink: "/"
        },
        {
          label: 'Registrati', icon: 'fa-minus',
          routerLink: "/signup",
          visible: !isLoggedIn
        },
        {
          label: "Pixelstreaming",
          routerLink: "/pixelstreaming",
          visible: isLoggedIn
        },
        {
          label: "Pannello di controllo",
          routerLink: "/controlpanel",
          visible: isLoggedIn
        },
        {
          label: "Carica asset",
          routerLink: "/upload",
          visible: isLoggedIn
        },
        {
          label: "Logout",
          routerLink: "/",
          visible: isLoggedIn,
          command: () => {
            this.loginService.logout();
          }
        }*/
      ]),
      startWith([])
  );
  }
  logout() {
    this.lastActivePageService.storeLastActivePage('/');
    this.loginService.logout();
    location.href = '/login';
  }

}

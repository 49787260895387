import {Component, Input} from '@angular/core';
import {StreamingDebugModel} from "../model/StreamingDebug";
import {ExtendedJwtPayload} from "../model/ExtendedJwtPayload";

@Component({
  selector: 'app-streaming-debug-overlay',
  templateUrl: './streaming-debug-overlay.component.html',
  styleUrls: ['./streaming-debug-overlay.component.scss']
})
export class StreamingDebugOverlayComponent {
  @Input() debugData: StreamingDebugModel | null = null;
  @Input() userRoles: string[] = [];
  @Input() debugLevel: number = 0;
  clJitter = 'green';
  getResolution(h: number): 'HD' | 'Full HD' | '4K' | string {
    switch (h) {
      case 720:
        return 'HD';
      case 1080:
        return 'Full HD';
      case 2160:
        return '4K';
      default:
        return h + 'p';
    }
  }

  /**
   * Jitter < 0.005 ms: Sicuro e fluido.
   * Jitter tra 0.005 e 0.015 ms: Potenzialmente problematico, attenzione a possibili scatti.
   * Jitter > 0.015 ms: Critico, alta probabilità di scatti, freeze, e lag visibili.
   * @param jitter
   */
  getJitterClass(jitter: number|undefined): 'green' | 'warning' | 'danger' {
    if (jitter === undefined) {
      return 'green';
    } else {
      if (jitter <= 0.005) {
        return 'green';
      } else if (jitter <= 0.015) {
        return 'warning';
      } else {
        return 'danger';
      }
    }
  }
}

import {inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable, Observer} from "rxjs";
import {SignalingServerResponse} from "../model/SignalingServerResponse";
import { environment } from 'src/environments/environment';
import {PixelStreamingService} from "./pixel-streaming.service";

@Injectable({
  providedIn: 'root'
})
export class SseService {
  _zone = inject(NgZone);
  streamingIsConnected = false;
  constructor(private pixelStreamingService: PixelStreamingService) { }

  getEventStream(uuid: string): Observable<SignalingServerResponse> {
    return new Observable<SignalingServerResponse>((observer: Observer<SignalingServerResponse>) => {
      let retryDelay = 1000; // Inizia con 1 secondo

      const connect = () => {
        const url = `${environment.matchmakingDomain}/events/${uuid}`;
        const eventSource = new EventSource(url);

        eventSource.onmessage = (event) => {
          this._zone.run(() => {
            try {
              const data: SignalingServerResponse = JSON.parse(event.data);
              this.streamingIsConnected = true;
              observer.next(data);
            } catch (error) {
              connect();
              console.error('Error parsing event data', error);
              observer.error(error);
            }
          });
        };

        eventSource.onerror = (error) => {
          this._zone.run(() => {
            console.log('Closing event source', error);
            eventSource.close();

            // Applica il backoff esponenziale
/*
            setTimeout(() => {
              retryDelay = Math.min(retryDelay * 2, 30000); // Max 30 secondi
              this.pixelStreamingService.wsUrl$.subscribe((url) => {
                if (!url) {
                  connect();
                }
              });
            }, retryDelay);
*/
          });
        };

        return eventSource;
      };

      const eventSource = connect();

      return () => {
        this._zone.run(() => {
          console.log('Closing event source');
        });
        eventSource.close();
      };
    });
  }
}

import { Component, inject } from '@angular/core';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  username: string = '';
  userService = inject(UserService);
  emailSent: boolean = false;
  resetPassword() {
    this.userService.sendPasswordResetEmail(this.username).subscribe({
      next: (data) => {
        this.emailSent = true;
      }
    });
  }

}

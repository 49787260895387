import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserEntityResponse } from '../model/UserEntityResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {

  user!: UserEntityResponse;
  userRole: string | undefined;
  userActivation: boolean | undefined;

  userRoleList = [
    { label: 'roles.user', value: 'user' },
    { label: 'roles.curator', value: 'curator' },
  ];

  userActivationList = [
    { label: 'activationStatus.activeUser', value: true },
    { label: 'activationStatus.disabledUser', value: false },
  ];

  userForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
  });

  constructor(
    private userService: UserService,
    private route:ActivatedRoute,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private router: Router,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      const userId = paramMap.get('userId');
      if (userId) {
        forkJoin([
          this.userService.getUser(userId),
          this.userService.getUserHighestRole(userId),
        ]).subscribe({
          next: response => {
            this.userRole = response[1];
            if (this.userRole !== 'user' && this.userRole !== 'curator') {
              this.messageService.add({severity: 'warn', summary: 'Warning. Unable to modify a system user', key: 'userLoadError'});
              return;
            }
            this.user = response[0];

            this.userActivation = this.user.enabled;

            this.userForm.setValue({
              username: this.user.username,
              email: this.user.email,
              firstName: this.user.firstName,
              lastName: this.user.lastName,
            });
          },
          error: error => {
            this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
            this.messageService.add({severity: 'error', summary: 'Error. User not found', key: 'userLoadError'});
          }
        });
      }
    });
  }

  onUserSubmit() {
    if (this.userForm.invalid) {
      return;
    }
    const email = this.userForm.value.email;
    const firstName = this.userForm.value.firstName;
    const lastName = this.userForm.value.lastName;
    this.userService.updateUserData(this.user.id, {
      email: email,
      firstName: firstName,
      lastName: lastName,
    }).subscribe({
      next: response => {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: this.translateService.instant('editUser.dataUpdated')});
      },
      error: err => {
        this.messageService.clear();
        this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
      },
    });
  }

  onUserRoleSubmit() {
    this.userService.getRealmRoles().subscribe({
      next: realmRoles => {

        const curatorRole = realmRoles.find(role => role.name == 'curator');
        if (!curatorRole) {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
          return;
        }

        if (this.userRole == 'user') {
          this.userService.deleteRoleMapping(this.user.id, curatorRole).subscribe({
            next: () => {
              this.messageService.clear();
              this.messageService.add({severity: 'success', summary: this.translateService.instant('editUser.userRoleUpdated')});
            },
            error: error => {
              this.messageService.clear();
              this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
            }
          });
        } else if (this.userRole == 'curator') {
          this.userService.addRoleToUser(this.user.id, curatorRole).subscribe({
              next: () => {
                this.messageService.clear();
                this.messageService.add({severity: 'success', summary: this.translateService.instant('editUser.userRoleUpdated')});
              },
              error: error => {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
              }
            }
          );
        } else {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
        }

      }
    });
  }

  onUserActivationSubmit() {
    this.userService.updateUserData(this.user.id, {
      enabled: this.userActivation,
    }).subscribe({
      next: response => {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: this.translateService.instant('editUser.dataUpdated')});
      },
      error: err => {
        this.messageService.clear();
        this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
      },
    });
  }

  sendResetPasswordRequest() {
    this.confirmDialogService.confirm({
      message: this.translateService.instant('editUser.sendResetPasswordMessage'),
      header: this.translateService.instant('editUser.sendResetPasswordHeader'),
        icon: 'pi pi-exclamation-triangle',
        key: 'deleteUserConfirmDialog',
        accept: () => {
          this.userService.sendPasswordResetEmail(this.user.email).subscribe({
            next: response => {
              this.messageService.clear();
              this.messageService.add({severity: 'success', summary: this.translateService.instant('editUser.requestPassowrdResetSuccess')});
            },
            error: err => {
              this.messageService.clear();
              this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
            },
          });
        },
    });
  }

  deleteUser() {
    this.confirmDialogService.confirm({
      message: this.translateService.instant('editUser.deleteUserMessage'),
      header: this.translateService.instant('editUser.deleteUserHeader'),
      icon: 'pi pi-exclamation-triangle',
      key: 'deleteUserConfirmDialog',
      accept: () => {
        this.userService.deleteUser(this.user.id).subscribe({
          next: () => {
            this.messageService.clear();
            this.messageService.add({severity: 'info', summary: this.translateService.instant('editUser.successUserDeleted') });
            this.router.navigate(['admin-control-panel']);
          },
          error: () => {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError') });
          },
        });
      },
    });
  }

}

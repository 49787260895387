<div [className]="'system-status-graph'" class="card flex justify-content-center">
<!--  <p-chart #chart type="doughnut" [data]="data" [options]="options"></p-chart>
  <p-chart #chartLocked type="doughnut" [data]="dataLocked" [options]="options"></p-chart>-->
  <div class="card-row">
    <p-card header="Istanze libere">
      <p class="m-0">
        <p-knob [size]="knob_size" [max]="maxInstance" [showValue]="true" valueColor="green" *ngIf="stats" [(ngModel)]="stats.ServersFree" [readonly]="true"></p-knob>
      </p>
    </p-card>
    <p-card header="Istanze occupate">
      <p class="m-0">
        <p-knob [size]="knob_size" [max]="maxInstance" [showValue]="true" valueColor="red" *ngIf="stats" [(ngModel)]="stats.ServersLocked" [readonly]="true"></p-knob>
      </p>
    </p-card>
    <p-card header="Istanze disponibili">
      <p class="m-0">
        <p-knob [size]="knob_size" [max]="maxInstance" [showValue]="true" valueColor="blue" *ngIf="stats" [(ngModel)]="stats.Servers" [readonly]="true"></p-knob>
      </p>
    </p-card>
  </div>
  <!-- Seconda riga -->
  <div class="card-row">
    <p-card header="Istanze in coda">
      <p class="m-0">
        <p-knob [size]="knob_size" [max]="maxInstance" [showValue]="true" valueColor="green" *ngIf="stats" [(ngModel)]="stats.QueuedInstances" [readonly]="true"></p-knob>
      </p>
    </p-card>
    <p-card header="Utenti in coda">
      <p class="m-0">
        <p-knob [size]="knob_size" [max]="maxInstance" [showValue]="true" valueColor="green" *ngIf="stats" [(ngModel)]="stats.UsersInQueue" [readonly]="true"></p-knob>
      </p>
    </p-card>
  </div>
</div>

import {ErrorHandler, inject, Injectable} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../service/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private notificationService: NotificationService) { }

    handleError(error: Error) {
      if (error instanceof HttpErrorResponse) {
        this.handleErrorHTTP(error);
      } else {
        console.error('An error occurred:', error);
        console.error('An error occurred:', error.stack);
        // this.notificationService.showDialog(error.message);
      }
    }
    handleErrorHTTP(error: HttpErrorResponse) {
      console.error(error);
      switch (error.status) {
        case 404:
          this.notificationService.showDialog("Risorsa non trovata");
          break;
        case 401:
          this.notificationService.showDialog("Credenziali non valide")
          break;
        case 409:
          this.notificationService.showDialog("Email già presente.");
          break;
        case 400:
          this.notificationService.showDialog("Errore nella richiesta");
          break;
        case 423:
          this.notificationService.showDialog("Email non convalidata");
          break;
        default:
          if (error.status === 0) {
            console.error('Unknown error: ', error.message)
          } else {
            this.notificationService.showDialog(error.message || 'An error occurred');
          }
      }
    }
}

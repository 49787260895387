export interface JwtToken {
  exp:                number;
  iat:                number;
  jti:                string;
  iss:                string;
  aud:                string[];
  sub:                string;
  typ:                string;
  azp:                string;
  session_state:      string;
  acr:                string;
  "allowed-origins":  string[];
  realm_access:       RealmAccess;
  resource_access:    ResourceAccess;
  scope:              string;
  sid:                string;
  email_verified:     boolean;
  roles:              string[];
  preferred_username: string;
  email:              string;
  given_name:         string;
  family_name:        string;
}

/*
* Roles to filter: admin, curator, user, guest
* */
export interface RealmAccess {
  roles: string[];
}

export interface ResourceAccess {
  "realm-management": RealmAccess;
  broker:             RealmAccess;
  account:            RealmAccess;
}

export const userRole = ["admin", "curator", "user", "guest"];

import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from "@angular/common/http";
import {AssetResponse} from "../model/AssetResponse";
import {Observable} from "rxjs";
import {UserGroupResponse} from "../model/UserGroupResponse";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MuseumService {
  apiUrl = "";
  httpClient = inject(HttpClient);
  constructor() {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`
  }

  uploadAsset(file: File, assetName: string): Observable<HttpEvent<AssetResponse>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('assetName', assetName);
    return this.httpClient.post<AssetResponse>(this.apiUrl + '/api/v2/ms/files', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getUserGroupFromRealmId(realmId: string): Observable<UserGroupResponse> {
    return this.httpClient.get<UserGroupResponse>(this.apiUrl + '/api/v2/ms/user-groups/' + realmId);
  }

  getExhibitionById(id: number) {
    return this.httpClient.get(this.apiUrl + '/api/v2/ms/exhibitions/' + id);
  }

  /**
   * Get all the exhibitions permitted to the user
   */
  getAllExhibition() {
    return this.httpClient.get(this.apiUrl + '/api/v2/ms/exhibitions');
  }
}

<div id="login-container">
  <div id="login-header">
    <a href=""><img [src]="'/assets/image/logo-black.png'"></a>
  </div>
  <div class="container-lg">
    <div class="form-container col-md-6 col-lg-5 mx-auto">
      <p-card *ngIf="!emailSent" header="{{ 'resetPassword.title' | translate }}" class="w-100">
        <div class="card-body">
          <div>
            <label for="email">{{ 'email' | translate }}</label>
            <input #email="ngModel" placeholder="" [(ngModel)]="username" type="email" id="email" pInputText required>
            <small *ngIf="!(email.valid || email.pristine)" id="email-help">{{ 'requiredField' | translate }}</small>
          </div>
          <p-button title="ResetPassword" (click)="resetPassword()" id="reset">{{ 'send' | translate }}</p-button>
          <p-button rel="nofollow" title="{{ 'back' | translate }}" label="{{ 'back' | translate }}" [link]="true" [routerLink]="'/login'"></p-button>

        </div>
      </p-card>

      <div *ngIf="emailSent" class="card-body">
        <p-card  header="{{ 'resetPassword.requestSent' | translate }}" class="w-100">
          <div class="card-body">
              <p>{{ 'resetPassword.description' | translate }}</p>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>

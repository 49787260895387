import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from '../utils/Validation';
import { UserService } from '../service/user.service';
import {ActivatedRoute, Router} from "@angular/router";


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  isAddUser = false;
  invalidFields: string[] = [];
  signupForm: FormGroup = new FormGroup({
    username: new FormControl(''),
    email: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl('')
  });
  submitted = false;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.isAddUser = this.route.snapshot.data['addUser'] || false;
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {validators: [Validation.match('password', 'confirmPassword')]});
  }

  onSubmit() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    }
    const username = this.signupForm.value.email || '';
    const email = this.signupForm.value.email || '';
    const firstName = this.signupForm.value.firstName || '';
    const lastName = this.signupForm.value.lastName || '';
    const password = this.signupForm.value.password || '';

    this.userService.signup(username, email, firstName, lastName, password).subscribe({
      next: response => {
        console.log(response);
        if (this.isAddUser) {
          this.router.navigate(['/admin-control-panel']);
        } else {
          this.router.navigate(["/"]);
        }
      }
    });
  }
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LoginService} from '../service/login.service';
import {MatchmakingService} from '../service/matchmaking.service';
import {PixelStreamingService} from '../service/pixel-streaming.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginResponse} from "../model/LoginResponse";
import {UserService} from "../service/user.service";
import {environment} from "../../environments/environment";
import {LastActivePageService} from "../service/last-active-page.service";
import {first} from "rxjs";
import {RemoteLogService} from "../service/remote-log.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit{
  username: string = '';
  password: string = '';
  showOtpInput!: boolean;
  otp!: number;
  isLoggedIn: boolean = false;
  user: any;
  disableFreeAccess = environment.DisableFreeAccess;
  isLoginDialogVisible = false;

  constructor(private loginService: LoginService, private matchmakingService: MatchmakingService,
              private pixelStreamingService: PixelStreamingService,
              private router: Router,private userService: UserService,
              private route: ActivatedRoute,
              private lastActivePageService: LastActivePageService,
              private remoteLogService: RemoteLogService
  ) {

  }

  ngOnInit(): void {
    this.showOtpInput = false;
    this.loginService.isAuthenticatedUser().subscribe(data => {
      this.isLoggedIn = data;
    });
  }

  login() {
    this.loginService.login(this.username, this.password).subscribe({
      next: response => {
        if (this.isLoginResponse(response)) {
          this.handleLoginResponse(response)
        } else {
          this.showOtpInput = true;
          this.loginService.setOtpToken(response.token)
          this.loginService.setRequestId(response.requestId)
          this.loginService.setOtpEnabled(true);
        }
      },
      error: (error) => {
        console.log('Login error.', error);
        try {
          this.remoteLogService.sendRemoteLog({
            currentUser: this.user,
            event: {data: {eventString: 'Login failed post request'}},
            debugData: {},
            errorDump: JSON.stringify(error),
            lastReceivedMessage: '',
            timestamp: new Date().toUTCString(),
          }, 'ERROR').pipe(first()).subscribe();
        } catch (error) {
          console.error('Error during sendRemoteLog:', error);
        }
      }
    });
  }

  sendOtp() {
    this.loginService.sendOtp(this.otp).subscribe({
      next: data => this.handleLoginResponse(data)
    });
  }

  private isLoginResponse(response: any): response is LoginResponse {
    return response !== null && typeof response === 'object' && 'access_token' in response;
  }

  private handleLoginResponse(loginResponse: LoginResponse, anonymous= false) {
    this.loginService.setAuthenticatedUser(true);
    this.loginService.setJwt(loginResponse.access_token);
    this.loginService.setRefreshToken(loginResponse.refresh_token);
    this.userService.setCurrentUser();
    if (anonymous) {
      this.router.navigate(['/pixelstreaming']);
    } else {
      this.router.navigate(this.lastActivePageService.getLastActivePage() ? [this.lastActivePageService.getLastActivePage()] : ['/home']);
    }
  }

  anonymouseAccess() {
    if (this.disableFreeAccess) {
      return;
    }
    this.loginService.loginAnonymous().subscribe({
      next: response => {
        if (this.isLoginResponse(response)) {
          this.handleLoginResponse(response, true);
        }
      }
    });
  }

  showLoginDialog() {
    this.isLoginDialogVisible = true;
  }
}


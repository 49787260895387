<div class="title-page">
  Statistiche Stanze
  <p-button styleClass="stat-export-btn" (onClick)="exportCsv()">Export</p-button>
</div>
<ng-container *ngIf="!requestError">
  <div class="row">
    <div class="col-md-6 col-sm-12 mb-4 px-3">
      <p-table class="table-custom" [value]="statisticsRoomsDataToShow" [columns]="statisticsRoomsColumns" (sortFunction)="sortData($event)" [customSort]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }} <p-sortIcon [field]="col.field" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-statistic let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              <ng-container *ngIf="col.field !== 'averageVisitDuration'">
                {{ statistic[col.field] }}
              </ng-container>
              <ng-container *ngIf="col.field === 'averageVisitDuration'">
                {{ statistic[col.field] * 60 * 1000 | metricTime }}
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col-md-6 col-sm-12 mb-4 px-3">
      <p-chart type="bar" [data]="chartData.chartData" [options]="chartData.chartOptions" height="360px"/>
    </div>
    <div class="mb-5">
      <p-paginator 
        (onPageChange)="changePage($event)"
        [first]="pageFirst"
        [rows]="pageRows"
        [totalRecords]="statisticsRoomsData.length"
        [rowsPerPageOptions]="[5, 10]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{currentPage} / {totalPages}" />
    </div>
  </div>
</ng-container>
<p-messages *ngIf="requestError" [value]="[{severity: 'error', summary: 'Errore caricamento statistiche stanze'}]"
  [enableService]="false" [closable]="false" />
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {delay, finalize, Observable} from "rxjs";
import {LoginService} from "../service/login.service";
import {LoaderService} from "../custom-shared/service/loader-service.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { LastActivePageService } from "../service/last-active-page.service";
import { SKIP_LOADER_SPINNER } from "./HttpContext";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string | undefined = undefined;
  refreshToken: string | undefined = undefined;
  isLoggedIn: boolean = false;
  constructor(
    private loginService: LoginService,
    private loaderService: LoaderService,
    private router: Router,
    private lastActivePageService: LastActivePageService
  ) {
    this.loginService.token$.subscribe(token => this.token = token)
    this.loginService.isAuthenticatedUser().subscribe(data => this.isLoggedIn = data);
    this.loginService.refreshToken$.subscribe(token => this.refreshToken = token);
  }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!request.context.get(SKIP_LOADER_SPINNER)) {
        this.loaderService.showLoader();
      }
      if (this.isLoggedIn) {
        this.validateToken();
      }
      request = request.clone({
        setHeaders: {
          authorization: this.token ? "Bearer " + this.token : '',
          "client-id": environment.client
        }
      });
      return next.handle(request).pipe(
        delay(0),
        finalize(() => {
          if (!request.context.get(SKIP_LOADER_SPINNER)) {
            this.loaderService.hideLoader();
          }
        }),
      );
    }

    validateToken() {
    console.log("validating token");
      this.loginService.validateJwt(this.token as string).subscribe({
        next: response => {
          console.log("validating token response");
          if (response.active) {
            console.log("token is valid");
            this.loginService.setAuthenticatedUser(true);
            this.loginService.setJwt(this.token as string);
            this.loginService.setAuthenticatedUser(true);
          } else {
            console.log("token is invalid, trying refresh token");
              this.loginService.refreshJwt(this.refreshToken as string).subscribe({
                next: response => {
                console.log("using refresh token");
                  if (response.access_token) {
                    this.loginService.setJwt(response.access_token);
                    this.loginService.setRefreshToken(response.refresh_token);
                    this.loginService.setAuthenticatedUser(true);
                  }
                 },
                 error: error => {
                     console.error('Errore refresh token');
                     console.error(error);
                     this.router.navigate(['/login']).then(succeeded => {
                       if (succeeded) {
                         this.lastActivePageService.storeLastActivePage('/');
                         this.loginService.logout();
                       }
                     });
                }
               });
             }
           }
        });
    }
}

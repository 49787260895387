<div class="container-lg full-width">
  <div class="form-container mx-auto d-block">
    <div class="title-page">
      {{'logs.title'|translate}}
    </div>
    <ng-container *ngIf="tableData">
      <p-table #dt1
               [lazy]="true" [lazyLoadOnInit]="true" [value]="tableData.data" [tableStyle]="{ 'width': '100%' }" [columns]="curatorEventColumns" class="table-custom" [paginator]="true"
               [rows]="pageSize" [totalRecords]="totalRecords" (onPage)="onPageChange($event)"
               [globalFilterFields]="globalFilterFields" [rowsPerPageOptions]="[5,10,20,50]"
               [resizableColumns]="true" [reorderableColumns]="true"
               columnResizeMode="expand" [showCurrentPageReport]="true" styleClass="p-datatable-gridlines p-datatable-sm p-datatable-striped"
               [filters]="filters" [filterDelay]="300" (onFilter)="onFilter($event)" (onSort)="onSort($event)"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.field === 'username' || col.field === 'entityType' || col.field === 'eventType'|| col.field === 'eventData'">
              {{col.header|translate}}
              <p-sortIcon [field]="col.field" *ngIf="col.field === 'entityId' || col.field === 'entityName' || col.field === 'userId' || col.field === 'eventDate'"></p-sortIcon>
              <p-columnFilter *ngIf="col.field === 'entityId'" type="numeric" field="entityId" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showButtons]="false" display="menu"></p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'entityName'" type="text" field="entityName" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu"></p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'userId'" type="text" field="userId" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu"></p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'entityType'" type="text" field="entityType" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" display="menu">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="entityTypeOptions" (onChange)="filter($event.value)">
                        <ng-template let-option pTemplate="item">
                          {{ option }}
                        </ng-template>
                    </p-dropdown>
                </ng-template>
              </p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'eventType'" type="text" field="eventType" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" display="menu">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="eventTypeOptions" (onChange)="filter($event.value)">
                        <ng-template let-option pTemplate="item">
                          {{ option }}
                        </ng-template>
                    </p-dropdown>
                </ng-template>
              </p-columnFilter>
              <p-columnFilter *ngIf="col.field === 'eventDate'" type="date" field="eventDate" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" display="menu" class="aaa">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <input style="visibility: collapse; position: absolute;" /> <!-- avoid autofocus on calendar -->
                  <app-time-filter [ngModel]="value" (ngModelChange)="filter($event)" styleClass="form-container-dark"></app-time-filter>
                </ng-template>
              </p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              <ng-container *ngIf="col.field === 'eventData'">
                <!-- Controlla se eventData è un array -->
                <span *ngIf="Array.isArray(rowData[col.field])">
                  <span class="metric-info" (click)="openModal(rowData[col.field][1])">{{'logs.more'|translate}}</span>
                  {{ rowData[col.field][0] }}...
                </span>
                <span *ngIf="!Array.isArray(rowData[col.field])">
                  {{ rowData[col.field] }}
                </span>
              </ng-container>
              <ng-container *ngIf="col.field === 'eventDate'">
                {{ rowData[col.field] | date:"dd/MM/YYYY HH:mm:ss":'UTC +4' }} <!-- temp fix (remove UTC +4 after BE fix)-->
              </ng-container>
              <ng-container *ngIf="col.field !== 'eventData' && col.field !== 'eventDate'">
                {{ rowData[col.field] }}
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>

    <p-dialog header="{{'logs.configChanges'|translate}}" [(visible)]="displayModal" [draggable]="false" [blockScroll]="true">
      <pre #modalContentText [innerHTML]="modalContent"></pre>
    </p-dialog>
  </div>
</div>    
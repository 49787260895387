import {ChangeDetectorRef, Component, inject, ViewChild, ViewEncapsulation} from '@angular/core';
import { Message } from 'primeng/api';
import {MuseumService} from "../service/museum.service";
import {HttpEventType} from "@angular/common/http";
import { FileUpload } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {Asset3dCheckerService, WarnMessagesModel} from './asset3d-checker.service';
import { LoaderService } from '../custom-shared/service/loader-service.service';

@Component({
  selector: 'app-asset-upload',
  templateUrl: './asset-upload.component.html',
  styleUrls: ['./asset-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssetUploadComponent {

  MAX_DIMENSION_MB = 200;
  ACCEPT_FILE_TYPE = '.jpg,.png,.zip,.fbx,.wav,.obj';
  @ViewChild(FileUpload) fileUploader?: FileUpload;
  selectedFile?: File;
  progress: number = 0;
  isLoading = false;
  museumService = inject(MuseumService);
  messageService = inject(MessageService);
  translateService = inject(TranslateService);
  loaderService = inject(LoaderService);
  asset3dCheckerService = inject(Asset3dCheckerService);
  cdr = inject(ChangeDetectorRef);
  assetName: string = '';
  warnMessages: Message[] = [];
  warnTags: Message[] = [];

  async onFileSelected(event: any) {
    console.log(event);
    this.messageService.clear('upload-mesh-tip');
    this.selectedFile = event.currentFiles[0];
    const fileExtension = this.selectedFile?.name.split('.').pop();
    this.asset3dCheckerService.warnTags.subscribe((tags) => { this.warnTags = tags; });
    this.asset3dCheckerService.warnMessages.subscribe((msgs) => {
      this.warnMessages = msgs;
      this.messageService.clear('upload-mesh-tip');
      this.messageService.addAll(this.warnMessages);
    });
    if (this.selectedFile && fileExtension === 'fbx') {
      this.loaderService.showLoader();
      this.asset3dCheckerService.warnTags.next([]);
      this.asset3dCheckerService.warnMessages.next([]);
      // 3d models guide lines check
      if(this.selectedFile) {
        const {warnMessages, warnTags} = await this.asset3dCheckerService.checkFBXModel(this.selectedFile);
      }
    } else if (this.selectedFile && fileExtension === 'obj') {
      console.log('OBJ file selected', fileExtension);
      this.messageService.clear('upload-mesh-tip');
      let msgs: WarnMessagesModel = {
        warnMessages: [{
          severity: 'warn',
          detail: fileExtension + ": " + this.translateService.instant('upload.obj_warning'),
          key: 'upload-mesh-tip',
        }],
        warnTags: [{
          severity: 'warning',
          detail: fileExtension,
          key: 'upload-mesh-tip',
        }]
      };
      msgs = {...msgs, warnMessages: [...msgs.warnMessages, ...this.warnMessages]};
      msgs = {...msgs, warnTags: [...msgs.warnTags, ...this.warnTags]};
      this.asset3dCheckerService.warnMessages.next(msgs.warnMessages);
      this.asset3dCheckerService.warnTags.next(msgs.warnTags);
    }

  }

  onFileRemoved() {
    this.messageService.clear('upload-mesh-tip');
    this.selectedFile = undefined;
    this.warnTags = [];
    this.warnMessages = [];
  }

  onUpload(): void {
    if (!this.selectedFile || !this.assetName) {
      return;
    }
    console.log(this.selectedFile);
    this.isLoading = true;
    this.museumService.uploadAsset(this.selectedFile, this.assetName).subscribe({
      next: response => {
        console.log(response)
        if (response.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * response.loaded / (response.total as number));
          this.cdr.detectChanges();
        }
        else if (response.type === HttpEventType.Response) {
          this.assetName = '';
          this.progress = 0;
          this.isLoading = false;
          this.fileUploader?.clear();
          this.messageService.add({severity: 'success', summary: this.translateService.instant('upload.succesful')});
          console.log(response.body);
          this.cdr.detectChanges();
        }
      },
      error: err => {
        this.progress = 0;
        this.isLoading = false;
        this.messageService.add({severity: 'error', summary: this.translateService.instant('requestError')});
        this.cdr.detectChanges();
      }
    });
  }
}

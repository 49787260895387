import {Component, inject, OnInit} from '@angular/core';
import {MetricsService} from "../service/metrics.service";
import {PaginatedResponse} from "../model/PaginatedResponse";
import {PaginatedRequest} from "../model/PaginatedRequest";
import {CuratorEventResponse} from "../model/CuratorEventResponse";
import {CuratorEventFilter} from "../model/CuratorEventFilter";

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit{
  private metricsService: MetricsService = inject(MetricsService)
  curatorEventData: PaginatedResponse<CuratorEventResponse> = {
    currentPage: 0,
    data: [] as CuratorEventResponse[],
    totalPages: 0,
    totalRecords: 0
  }
  paginatedRequest: PaginatedRequest<CuratorEventFilter> = {
    // valori di default iniziali per la paginazione
    page: 0,
    pageSize: 25,
    request: {} as CuratorEventFilter
  }
  ngOnInit(): void {
    this.metricsService.getCuratorMetrics(this.paginatedRequest).subscribe({
      next: data => {
        this.curatorEventData = data;
        this.curatorEventData.data.forEach(element => {
          let regex = /\s<|\"\{/
          if (typeof element.eventData === "string") {
            if (element.eventData.match(regex)){
              const index = element.eventData.search(regex);
              // @ts-ignore
              const matchLength = element.eventData.substring(index).match(regex)[0].length;
              element.eventData = [element.eventData.substring(0, Math.min(index, this.metricsService.eventDataUpdateMessageCutLength)), element.eventData.substring(index + matchLength - 2)];
            }
          }
        })
        console.log(data);
      }
    });
  }

}

import {Component, inject, OnInit} from '@angular/core';
import {UserService} from "../service/user.service";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.scss']
})
export class VerifyemailComponent implements OnInit{
  private readonly userService = inject(UserService);
  route = inject(ActivatedRoute);
  showSuccess = false;
  constructor() {}

  ngOnInit(): void {
    let token = this.route.snapshot.paramMap.get('token');
    if (token) {
      this.userService.verifyEmail(token).subscribe({
        next: (data) => {
          this.showSuccess = true;
        }
      });
    }
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from './service/login.service';
import { LastActivePageService } from './service/last-active-page.service';
import { UserService } from './service/user.service';

export const authGuard: CanActivateFn = (route, state) => {
  const loginService = inject(LoginService);
  const userService = inject(UserService);
  const router = inject(Router);
  const lastActivePageService = inject(LastActivePageService);

  const isAuthenticated = loginService.isAuthenticated.value;
  if (!isAuthenticated) {
    lastActivePageService.storeLastActivePage(state.url);
    return router.parseUrl('/login');
  }

  const roles = userService.currentUser?.realm_access?.roles;
  if (!roles || roles.includes('guest')) {
    loginService.logout();
    return router.parseUrl('/login');
  }
  if (!roles.includes(route.data['role'])) {
    return router.parseUrl('/home');
  }

  return true;
};

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JwtToken, userRole} from "../../model/JwtToken";
import {ExtendedJwtPayload} from "../../model/ExtendedJwtPayload";

@Component({
  selector: 'app-navbar-userspace',
  styleUrls: ['./navbar-userspace.component.scss'],
  template: `
    <div id="navbar-userspace-container" class="d-flex align-items-center">
      <dl class="mb-0 d-none d-sm-block">
        <dt>{{name}}</dt>
        <dd>{{ 'roles.' + description | translate }}</dd>
      </dl>
      <!-- Bottone hamburger menu
      <p-button id="hmenu" title="Menu" >
        <i class="pi pi-bars"></i>
      </p-button>-->
      <p-button id="signin" title="Sign Out" (onClick)="logoutClick()">
        <svg xmlns="http://www.w3.org/2000/svg" width="41.366" height="41.368" viewBox="0 0 41.366 41.368">
          <path id="signOut" d="M40.964,21.659l-4.826,4.826a1.379,1.379,0,0,1-1.951-1.949l2.472-2.472H27.577a1.379,1.379,0,1,1,0-2.758h9.082l-2.472-2.472a1.378,1.378,0,0,1,1.949-1.949l4.826,4.826a1.379,1.379,0,0,1,0,1.949Zm-7.87-9.939V5.515H22.063V35.851H33.094V29.646a1.379,1.379,0,1,1,2.758,0V37.23a1.379,1.379,0,0,1-1.379,1.379H22.063v1.379a1.381,1.381,0,0,1-1.379,1.379,1.48,1.48,0,0,1-.195-.014L1.184,38.6A1.379,1.379,0,0,1,0,37.23V4.136A1.379,1.379,0,0,1,1.184,2.771L20.489.013a1.38,1.38,0,0,1,1.574,1.365V2.757h12.41a1.379,1.379,0,0,1,1.379,1.379V11.72a1.379,1.379,0,0,1-2.758,0ZM19.3,37.23V2.967L2.758,5.332v30.7L19.3,38.4V37.228ZM15.858,19.3a1.379,1.379,0,1,0,1.379,1.379A1.379,1.379,0,0,0,15.858,19.3Z" transform="translate(0 0.001)" fill="#fff"/>
        </svg>
      </p-button>
      <!-- Hamburger menu
      <div class="hamburger-menu">
        <ul>
          <li><a href="">Home</a></li>
          <li><a href="">Gestisci museo</a></li>
          <li><a href="">Gestione account</a></li>
          <li><a href="">Gestione utenti</a></li>
          <li><a href="">File upload</a></li>
        </ul>
      </div>
      -->
    </div>
  `
})
export class NavbarUserspaceComponent implements OnInit {
  @Input() currentUser: ExtendedJwtPayload | undefined = undefined;
  @Output() logout: EventEmitter<null> = new EventEmitter<null>();
  name = '-';
  description = '-';

  logoutClick() {
    this.logout.emit()
  }

  ngOnInit(): void {
    console.log(this.currentUser);
    if (this.currentUser) {
      this.name = `${this.currentUser.family_name} ${this.currentUser.given_name}`
      this.description = `${this.getUserRole()}`
    }
  }

  /**
   * return valid role from roles list
   */
  getUserRole(): string {
    let role = undefined;
    if (this.currentUser) {
      if (this.currentUser.realm_access?.roles) {
        role = userRole.find(x => this.currentUser?.realm_access?.roles.includes(x));
      }
    }
    return role ? role : this.description;
  }

}

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LoginService } from './service/login.service';
import { map } from 'rxjs';
import {LastActivePageService} from './service/last-active-page.service';
import { UserService } from './service/user.service';

export const pixelstreamingGuard: CanActivateFn = (route, state) => {
  const loginService = inject(LoginService);
  const userService = inject(UserService);
  const lastActivePageService = inject(LastActivePageService);

  let isAuthenticated = loginService.isAuthenticated.value;
  if (!isAuthenticated) {
    return loginService.loginAnonymous().pipe(map(
      res => {
        loginService.setAuthenticatedUser(true);
        loginService.setJwt(res.access_token);
        loginService.setRefreshToken(res.refresh_token);
        userService.setCurrentUser();
        return true;
      }
    ));
  }
  lastActivePageService.storeLastActivePage(state.url);
  return isAuthenticated;
};

import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ExhibitionEventFilter} from "../model/ExhibitionEventFilter";
import {flatMap, mergeMap, Observable} from "rxjs";
import {ExhibitionEventResponse} from "../model/ExhibitionEventResponse";
import {AssetEventFilter} from "../model/AssetEventFilter";
import {AssetEventResponse} from "../model/AssetEventResponse";
import {LoginService} from "./login.service";
import {MuseumService} from "./museum.service";
import JwtUtil from "../utils/JwtUtil";
import {QueryResultResponse} from "../model/QueryResultResponse";
import {PaginatedResponse} from "../model/PaginatedResponse";
import {PaginatedRequest} from "../model/PaginatedRequest";
import {CuratorEventFilter} from "../model/CuratorEventFilter";
import {CuratorEventResponse} from "../model/CuratorEventResponse";
import { environment } from 'src/environments/environment';

export interface SortCriteria {
  sortCriteria?: {
    field: string;
    order: 'ASC' | 'DESC';
  }
}

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  private httpClient: HttpClient = inject(HttpClient);
  private museumService: MuseumService = inject(MuseumService);
  private jwtUtil: JwtUtil = inject(JwtUtil);
  realmId: string | undefined;
  apiUrl = "";
  eventDataUpdateMessageCutLength = 46;
  constructor() {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`
    this.realmId = this.jwtUtil.getDecodedToken()?.realm_id;
  }

  getCuratorMetrics(curatorEventFilter: PaginatedRequest<CuratorEventFilter> & SortCriteria): Observable<PaginatedResponse<CuratorEventResponse>> {
    return this.httpClient.post<PaginatedResponse<CuratorEventResponse>>(this.apiUrl + '/api/v1/metrics/events/curator-metrics' , curatorEventFilter);
  }
}

import {Component, OnInit} from '@angular/core';
import {LoginService} from "../service/login.service";
import {UserService} from "../service/user.service";
import {ExtendedJwtPayload} from "../model/ExtendedJwtPayload";
import {MenuItem} from "primeng/api";
import {MainMenuItemModel} from "../model/MainMenuItem";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public user: ExtendedJwtPayload | null = null;
  public authUserMenu: MainMenuItemModel[] = []
  constructor(private userService: UserService) {
  }
  ngOnInit(): void {
    this.user = this.userService.currentUser;
    if (this.user) {
      this.authUserMenu = [
        {
          label: "home.exhibitionButton.label",
          description: "home.exhibitionButton.description",
          routerLink: "/pixelstreaming",
          visible: this.user?.realm_access?.roles.includes('user') && !this.user?.realm_access?.roles.includes('curator') && !this.user?.realm_access?.roles.includes('admin'),
          icon: 'shows.svg'
        },
        {
          label: "home.exhibitinoManageButton.label",
          description: "home.exhibitinoManageButton.description",
          routerLink: "/pixelstreaming",
          visible: this.user?.realm_access?.roles.includes('curator') || this.user?.realm_access?.roles.includes('admin'),
          icon: 'museum.svg'
        },
        {
          label: "home.accountButton.label",
          description: "home.accountButton.description",
          routerLink: "/controlpanel",
          visible: this.user?.realm_access?.roles.includes('user'),
          icon: 'users.svg'
        },
        {
          label: "home.usersButton.label",
          description: "home.usersButton.description",
          routerLink: "/admin-control-panel",
          visible: this.user?.realm_access?.roles.includes('admin'),
          icon: 'users-cog.svg'
        },
        {
          label: "home.fileUploadButton.label",
          description: "home.fileUploadButton.description",
          routerLink: "/upload",
          visible: this.user?.realm_access?.roles.includes('curator') || this.user?.realm_access?.roles.includes('admin'),
          icon: "upload.svg"
        },
        {
          label: "home.logsButton.label",
          description: "home.logsButton.description",
          routerLink: "/logs",
          visible: this.user?.realm_access?.roles.includes('admin'),
          icon: "file-chart-line.svg"
        },
        {
          label: "home.statisticsButton.label",
          description: "home.statisticsButton.description",
          routerLink: "/statistics",
          visible: this.user?.realm_access?.roles.includes('admin'),
          icon: "statistics.svg"
        },
        {
          label: "home.exportButton.label",
          description: "home.exportButton.description",
          routerLink: "/export",
          visible: this.user?.realm_access?.roles.includes('admin'),
          icon: "export.svg"
        }
      ]
    }
  }

}

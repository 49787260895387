import {Component, Input} from '@angular/core';
import {MainMenuItemModel} from "../model/MainMenuItem";

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.scss']
})
export class MainmenuComponent {
  @Input() menu: MainMenuItemModel[] | null = null;

}

import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-fps-indicator',
  templateUrl: './fps-indicator.component.html',
  styleUrls: ['./fps-indicator.component.scss']
})
export class FpsIndicatorComponent implements OnChanges {
  @Input() fps: number = 60; // FPS ricevuti come input
  @Input() debugLevel: number = 0; // Livello di debug

  fpsClass: string = 'green'; // Classe di default

  private lastWarningChange: number = 0;
  private lastDangerChange: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fps']) {
      this.updateFpsClass(this.fps);
    }
  }
  updateFpsClass(fps: number) {
    const now = Date.now();

    if (fps < 30 && now - this.lastDangerChange > 1000) {
      this.fpsClass = 'danger';
      this.lastDangerChange = now;
    } else if (fps < 55 && now - this.lastWarningChange > 1000) {
      this.fpsClass = 'warning';
      this.lastWarningChange = now;
    } else if (fps >= 55) {
      this.fpsClass = 'green'; // Ripristina il verde
      this.lastWarningChange = 0;
      this.lastDangerChange = 0;
    }
  }


}

<div id="login-container">
  <div id="login-header">
    <a href="/home"><img [src]="'/assets/image/logo-black.png'"></a>
  </div>
  <ng-container *ngIf="showSuccess">
    <div class="container-lg">
      <div class="form-container col-md-6 col-lg-5 mx-auto">
        <h3>Email verificata con successo</h3>
      </div>
    </div>
  </ng-container>
</div>

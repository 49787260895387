import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {MatchmakingService} from "../service/matchmaking.service";
import {LoginService} from "../service/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, Subscription, of, retryWhen, scan, delay, retry, Subject, takeUntil, first} from "rxjs";
import {SseService} from "../service/sse.service";
import { MuseumService } from '../service/museum.service';
import { UserService } from '../service/user.service';
import { ExtendedJwtPayload } from '../model/ExtendedJwtPayload';
import { PixelStreamingService } from '../service/pixel-streaming.service';
import { environment } from 'src/environments/environment';
import {LoaderService} from "../custom-shared/service/loader-service.service";
import isMobile from 'is-mobile';
import JwtUtil from '../utils/JwtUtil';
import { TranslateService } from '@ngx-translate/core';
import {SignalingServerResponse} from "../model/SignalingServerResponse";
import {RemoteLogService} from "../service/remote-log.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-pixel-streamming-container',
  templateUrl: './pixel-streamming-container.component.html',
  styleUrls: ['./pixel-streamming-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PixelStreammingContainerComponent implements OnInit, OnDestroy {
  jwtToken: string = '';
  public user: ExtendedJwtPayload | null = null;
  signalUrl: string = '';
  retryTime = environment.matchmakingTimeout;
  intervalId: any;
  isPixelStreamingActive = false;
  isPixelStreamingReady = false;
  isPixelStreamingVisible = false;
  invalidExhibition = false;
  noExhibitions = false;
  matchmakingInterval: any;
  sTimer = 0;
  maxIncrement = 4;
  sIntervalId: any;
  exhibitionId: number | null = null;
  signalSecurity = true;
  queuePosition: number = 0;
  inQueue: boolean = false;
  isAutoTutorial = true;
  private eventSubscription!: Subscription;
  private ngUnsubscribe = new Subject<void>();

  tutorialImages: any[] = [];

  beforeunload = (event: any) => {
    event.returnValue = 'Attenzione. Ogni modifica non salvata sarà persa.';
    return event;
  };

  constructor(
    private matchmakingService: MatchmakingService,
    private loggingService: LoginService,
    private route: ActivatedRoute,
    private sseService: SseService,
    private museumService: MuseumService,
    private userService: UserService,
    private pixelStreamingService: PixelStreamingService,
    private jwtUtil: JwtUtil,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private remoteLogService: RemoteLogService,
    private location: Location, private router: Router
  ) {
    this.exhibitionId = this.route.snapshot.paramMap.get('exhibitionId') ? parseInt(this.route.snapshot.paramMap.get('exhibitionId') as string) : null;
    this.loggingService.token$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(token => {
      if (token) {
        this.jwtToken = token;
      }
    });
  }

  ngOnInit(): void {
    this.pixelStreamingService.isDisconnected = false;
    this.pixelStreamingService.showTutorial.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => this.showTutorial(res));
    const tutorialDevice = isMobile({ tablet: true, featureDetect: true }) ? 'MOBILE' : 'PC';
    const tutorialLanguage = this.translateService.currentLang === 'it-IT' ? 'it-IT' : 'en';

    if (this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes("admin") || this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes("curator")) {
      this.tutorialImages = [
        { itemImageSrc: 'assets/tutorial/Observa-Tutorial-Logo.png' },
      ];
    } else {
      this.tutorialImages = [
        { itemImageSrc: `assets/tutorial/${tutorialLanguage}/1.png` },
        { itemImageSrc: `assets/tutorial/${tutorialLanguage}/4-${tutorialDevice}.png` },
        { itemImageSrc: `assets/tutorial/${tutorialLanguage}/2-${tutorialDevice}.png` },
        { itemImageSrc: `assets/tutorial/${tutorialLanguage}/3-${tutorialDevice}.png` },
        { itemImageSrc: `assets/tutorial/${tutorialLanguage}/5-${tutorialDevice}.png` },
        { itemImageSrc: `assets/tutorial/${tutorialLanguage}/6.png` },
      ];
    }
    this.user = this.userService.currentUser;
    if (this.user?.realm_access?.roles.includes('curator') || this.user?.realm_access?.roles.includes('admin')) {
      window.addEventListener('beforeunload', this.beforeunload);
    }

    if (this.exhibitionId) {
      // check exhibition validity for logged user
      this.museumService.getExhibitionById(this.exhibitionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => this.getSignallingServer(),
        error: () => this.invalidExhibition = true,
      });
    } else {
      this.museumService.getAllExhibition().pipe(takeUntil(this.ngUnsubscribe)).subscribe((exhibitions: any) => {
        console.log("Exhibitions: ", exhibitions);
        if ((exhibitions && exhibitions.length > 0) ||
          this.user?.realm_access?.roles.includes('admin') || this.user?.realm_access?.roles.includes('curator')) {
          this.getSignallingServer();
        } else {
          this.noExhibitions = true;
        }
      });
    }
    // Intercetta l'evento popstate che viene emesso quando viene premuto il tasto "back"
    this.location.subscribe(() => {
      // Azione da eseguire quando viene premuto "back"
      console.log('Tasto back premuto!');

      // Esegui il refresh della pagina
      window.location.reload();

      // Oppure, se vuoi gestire la navigazione con il router senza refresh:
      // this.router.navigate(['/il-tuo-percorso']);
    });
  }

  countDown() {
    if(!this.sIntervalId) {
      document.body.classList.add('overflow-hidden');
      this.sTimer = 0;
      let remaining = 100;
      this.sIntervalId = setInterval(() => {
        const maxIncrement = Math.min(remaining, this.maxIncrement);
        const increment = Math.floor(Math.random() * (maxIncrement + 1));
        this.sTimer += increment;
        remaining -= increment;
        if (this.sTimer >= 99 || remaining <= 0) {
          clearInterval(this.sIntervalId);
          this.sTimer = 99;
        }
      }, 1000);
    }
  }

  getSignallingServer() {
    this.retryTime = environment.matchmakingTimeout;
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.matchmakingService.getMatchmaking(this.jwtToken).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (signalRes: SignalingServerResponse) => {
        console.log('Signaling response: ', JSON.stringify(signalRes));
        if (!environment.matchmakingEnable) {
          this.isPixelStreamingActive = true;
          this.countDown();
          this.signalUrl = signalRes.signallingServer === "" ? `${signalRes.address}:${signalRes.port}` : signalRes.signallingServer;
          this.signalUrl = `wss://${this.signalUrl}`;
          setTimeout(() => {
            this.pixelStreamingService.setWsUrl(this.signalUrl);
            setTimeout(() => {
              this.isPixelStreamingReady = true;
            }, 5000);
          }, 2500);
        } else {
          if (signalRes.queueID) {
            console.log('Waiting for a match');
            console.log('QueueID: ', signalRes.queueID);
            this.listenToEvents(signalRes.queueID);
          }
          this.isPixelStreamingActive = false;
        }
      }, error: (error) => {
      console.error('Error getting signaling server: ', error);
      try {
        this.remoteLogService.sendRemoteLog({
          currentUser: this.user,
          event: {data: {eventString: 'Error getting signaling server'}},
          debugData: {},
          errorDump: JSON.stringify(error),
          lastReceivedMessage: '',
          timestamp: new Date().toUTCString(),
        }, 'ERROR').pipe(first()).subscribe();
      } catch (error) {
        console.error('Error during sendRemoteLog:', error);
      }
    }
    });
  }

  listenToEvents(uuid: string) {
    this.eventSubscription = this.sseService.getEventStream(uuid).subscribe({
      next: (event: SignalingServerResponse) => {
        console.log('Received event:', event)
          if (event.queuePosition && event.serversFull) {
              console.log("In queue: " + this.inQueue);
              this.queuePosition = event.queuePosition ? event.queuePosition : 0;
              this.inQueue = true;
          } else if (event.queuePosition && !event.serversFull) {
              this.queuePosition = event.queuePosition ? event.queuePosition : 0;
              this.inQueue = false;
              this.isPixelStreamingActive = true;
              this.countDown();
          } else {
            this.maxIncrement = (100 - this.sTimer) / 3;
            this.signalUrl = event.signallingServer === "" ? `${event.address}:${event.port}` : event.signallingServer;
            if (event.signallingServer === "") {
              this.signalUrl = `ws://${this.signalUrl}`;
            } else {
              this.signalUrl = `wss://${this.signalUrl}`;
            }
            if (this.signalUrl && !this.signalUrl.includes('undefined')) {
              setTimeout(() => {
                this.pixelStreamingService.setWsUrl(this.signalUrl);

                // using settimeout instead of event because VideoInitialized is not always fired
                // this.pixelStreamingService.psVideoInitialized.subscribe(res => this.isPixelStreamingReady = true);
                setTimeout(() => {
                  this.isPixelStreamingReady = true;
                }, 5000);
              }, 2500);
              this.isPixelStreamingActive = true;
              this.countDown();
            }
          }

        },
        error: (error) => {
          console.error('SSE error: ', error)
        },
      });
        // Process the event data here

  }

  goToPixelStreaming() {
    document.body.classList.remove('overflow-hidden');
    this.isPixelStreamingVisible = true;
    this.isAutoTutorial = false;
    document.getElementById('playOverlay')?.click();
  }

  showTutorial(show: boolean) {
    if (show) {
      document.body.classList.add('overflow-hidden');
      this.isPixelStreamingVisible = false;
    } else {
      this.goToPixelStreaming();
    }
  }

  hideTutorialBtn() {
    this.pixelStreamingService.showTutorial.next(false);
  }

  ngOnDestroy() {
    console.log('PixelStreamingContainerComponent destroyed');
    if (this.matchmakingInterval) {
      console.log('Clearing matchmaking interval');
      clearInterval(this.matchmakingInterval);
    }
    if (this.intervalId) {
      console.log('Clearing tutorial interval');
      clearInterval(this.intervalId);
    }
    document.body.classList.remove('overflow-hidden');
    window.removeEventListener('beforeunload', this.beforeunload);
    this.eventSubscription?.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  canDeactivate(): Observable<boolean> {
    if (this.user?.realm_access?.roles.includes('curator') || this.user?.realm_access?.roles.includes('admin')) {
      if (this.pixelStreamingService.isDisconnected) {
        return of(true);
      }
      const result = window.confirm('Attenzione. Ogni modifica non salvata sarà persa.');
      return of(result);
    }
    return of(true);
  }
}

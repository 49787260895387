<div class="container-lg">
  <div class="form-container col-md-6 col-lg-5 mx-auto">
    <p-card header="{{'controlPanel.title'|translate}}">
      <div class="card-body">
        <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
          <p-card>
            <div class="card-body">
              <div class="field">
                <label for="email">{{'email'|translate}}</label>
                <input type="email" placeholder="{{'email'|translate}}" id="email" pInputText formControlName="email">
              </div>
              <div class="field">
                <label for="firstName">{{'firstName'|translate}}</label>
                <input type="text" placeholder="{{'firstName'|translate}}" id="firstName" pInputText formControlName="firstName">
              </div>
              <div class="field">
                <label for="lastName">{{'lastName'|translate}}</label>
                <input type="text" placeholder="{{'lastName'|translate}}" id="lastName" pInputText formControlName="lastName">
              </div>
              <div class="field">
                <label for="password">{{'password'|translate}}</label>
                <input type="password" placeholder="{{'password'|translate}}" id="password" pInputText formControlName="password">
              </div>
              <div class="field">
                <input type="password" placeholder="{{'confirmPassword'|translate}}" id="confirmPassword" pInputText formControlName="confirmPassword">
                <small class="p-error block" *ngIf="
              updateForm.get('confirmPassword')?.invalid && updateForm.get('confirmPassword')?.dirty
            ">{{'invalidConfirmPassword'|translate}}</small>
              </div>
              <div *ngIf="!otpEnabled">
                <p-button id="add-mfa" [link]="true" routerLink="/mfa" class="add-mfa">
                  {{'controlPanel.twoFactorAuthentication'|translate}}
                </p-button>
              </div>
              <div *ngIf="otpEnabled">
                <p-button id="remove-mfa" [link]="true" (onClick)="removeMfa()">
                  {{'controlPanel.twoFactorAuthenticationRemove'|translate}}
                </p-button>
              </div>
              <div *ngIf="isUserDeleatable">
                <p-button class="add-mfa" [link]="true" (onClick)="deleteUser()">
                  {{'controlPanel.deleteUserLabel'|translate}}
                </p-button>
              </div>
              <div class="mt-md-4 d-flex justify-content-between">
                <p-button type="button" label="{{'cancel'|translate}}" routerLink="/" class="p-button-light"></p-button>
                <p-button type="submit" label="{{'save'|translate}}" [disabled]="updateForm.invalid"></p-button>
              </div>
            </div>
          </p-card>
        </form>
      </div>
    </p-card>
  </div>
</div>

<p-confirmDialog key="deleteSelfUserConfirmDialog" icon="pi pi-exclamation-triangle"
  header="{{ 'controlPanel.deleteSelfUserHeader' | translate }}"
  message="{{ 'controlPanel.deleteSelfUserMessage' | translate }}" acceptButtonStyleClass="confirm-button"
  rejectButtonStyleClass="confirm-button light" rejectLabel="{{'controlPanel.deleteSelfAccountRejectLabel'|translate}}"
  acceptLabel="{{'controlPanel.deleteSelfAccountAcceptLabel'|translate}}" />

<p-confirmDialog key="deleteMfaConfirmDialog" icon="pi pi-exclamation-triangle"
  header="{{ 'controlPanel.deleteMfaHeader' | translate }}" message="{{ 'controlPanel.deleteMfaMessage' | translate }}"
  acceptButtonStyleClass="confirm-button" rejectButtonStyleClass="confirm-button light"
  rejectLabel="{{'cancel'|translate}}" acceptLabel="{{'controlPanel.deleteMfaAcceptLabel'|translate}}" />
import {Component, ViewEncapsulation} from '@angular/core';
import {ExternalLogModel, RemoteLogEntryModel, RemoteLogService} from "../service/remote-log.service";
import {first} from "rxjs";

@Component({
  selector: 'app-debug-log-manager',
  templateUrl: './debug-log-manager.component.html',
  styleUrls: ['./debug-log-manager.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DebugLogManagerComponent {
  constructor(private remoteLogService: RemoteLogService) { }
  logs: ExternalLogModel[] = [];
  autoRefresh = false;
  refreshInterval = 5000;
  refreshIntervalId: any;

  ngOnInit() {
    this.remoteLogService.getRemoteLogs().subscribe((logs) => {
      this.logs = logs.sort((a, b) => {
        if (a.id && b.id) {
          return b.id > a.id ? 1 : -1;
        }
        return 0;
      });
    });
    this.toggleRefresh();
  }

  extractEventString(logEntryElement: any) {
    if (logEntryElement instanceof Object) {
      return logEntryElement?.data?.eventString;
    } else {
      try {
        return JSON.parse(logEntryElement)?.data?.eventString;
      } catch (error) {
        return logEntryElement;
      }
    }
  }

  extractLastMessage(logEntryElement: any) {
    if (logEntryElement instanceof Object) {
      return `${logEntryElement?.type}/${logEntryElement?.payload?.type}`;
    } else {
      try {
        const parsed = JSON.parse(logEntryElement);
        if (parsed) {
          return `${parsed?.type}/${JSON.parse(parsed?.payload)?.type}`;
        }
      } catch (error) {
        return logEntryElement;
      }
      return `no message`;
    }
  }
  testSendLog() {
    try {
      this.remoteLogService.sendRemoteLog({
        currentUser: 'debug',
        event: 'debug',
        debugData: {},
        errorDump: {},
        lastReceivedMessage: 'debug message',
        timestamp: new Date().toUTCString(),
      }, 'debug').pipe(first()).subscribe(() => this.refreshLogs());
    } catch (error) {
      console.error('Error during sendRemoteLog:', error);
    }
  }

  refreshLogs() {
    this.remoteLogService.getRemoteLogs().subscribe((logs) => {
      this.logs = logs.sort((a, b) => {
        if (a.id && b.id) {
          return b.id > a.id ? 1 : -1;
        }
        return 0;
      });
    });
  }

  deleteLog(id: string) {
    this.remoteLogService.deleteRemoteLog(+id).pipe(first()).subscribe(() => {
      setTimeout(() => this.refreshLogs(), 500);
    });
  }

  toggleRefresh() {
    if (this.autoRefresh) {
      this.refreshIntervalId = setInterval(() => this.refreshLogs(), this.refreshInterval);
    } else {
      clearInterval(this.refreshIntervalId);
    }
  }
}
